import {Col, Row, Card, CardBody, CardHeader, Collapse, ButtonGroup, ButtonToolbar } from 'reactstrap';
import moment from 'moment';

import { Link, Navigate, NavLink } from 'react-router-dom';

import '../../../scss/dashboardStyles.css';
import React, { useEffect, useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { Calendar } from 'primereact/calendar';
import FilterOperations from './FilterOperations';
import CustomBadge from './CustomBadge';

import { Paginator } from 'primereact/paginator';
import { CSVLink } from "react-csv";
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { withTranslation } from 'react-i18next';
import { MultiSelect } from 'primereact/multiselect';
import { AutoComplete } from 'primereact/autocomplete';

//Modals
import ShowHideColumnsModal from '../CommonModals/ShowHideColumnsModal';
import FormModal from '../../Form/FormModal';
import DeleteRowModal from '../CommonModals/DeleteRowModal';
import ViewModal from '../CommonModals/viewModal';
import BulkUploadModal from '../CommonModals/BulkUploadModal';
import ConfirmationModal from '../CommonModals/ConfirmationModal';
import SessionExpiryModal from '../CommonModals/SessionexpiryModal';
import PreviewModal from '../CommonModals/PreviewModal';
import SendEmailModal from '../CommonModals/SendEmailModal';
import DeleteFilterModal from '../CommonModals/DeleteFilterModal';

// import UserPasswordResetModal from '../Modals/UserPasswordResetModal';
// fetch method for server call
import fetchMethodRequest from '../../../config/service';
import config from '../../../config/config';
import configImages from '../../../config/configImages';
import configMessages from '../../../config/configMessages';
import bulkSampleFiles from '../../../config/configSampleFiles'
import apiCall from '../../../config/apiCalls';
//Render Date picker
import RenderIntervalDatePickerField from '../../../shared/components/form/IntervalDatePicker';
// Permissions
// import RolePermissions from '../Modals/Permissions';

// Toaster message
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';

// Date Formate
import dateFormats from '../../UI/FormatDate/formatDate';
//Loader
import Loader from '../../App/Loader';
//store
import store from '../../App/store'
import validate from '../../Validations/validate'
import { reduxForm, } from 'redux-form'
import { connect } from 'react-redux'
import crypto from "crypto-js";

import PaginatorComponent from './PaginatorComponent';
import { element } from 'prop-types';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCog, faPrint, faGripHorizontal, faList } from "@fortawesome/free-solid-svg-icons";
import { Button } from 'primereact/button';
import { formatPropType } from '../../../utils/utils';
import FunnelFilter from './FunnelFilter';
import RolePermissions from "../CommonModals/Permissions";

library.add(faCog, faPrint, faGripHorizontal, faList);

// tooltip styles
const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f17038',
    color: '#fff',
    boxShadow: theme.shadows[10],
    fontSize: 13,
    fontWeight: '500',
    margin: 0,
    textTransform: 'capitalize',
  },
}))(Tooltip);

const AnotherLightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: config.templateColor,
    color: '#fff',
    boxShadow: theme.shadows[10],
    fontSize: 14,
    fontWeight: '500',
    margin: 0,
  },
}))(Tooltip);

function DataTables(props) {

  const [allUsersData, setAllUsersData] = useState([]);
  const [deleteRowDataId, setDeleteRowDataId] = useState({});
  // const [tableFields, setTableFields] = useState(props.getTableFields());
  const [originalTableFields, setOriginalTableFields] = useState(props.getTableFields());
  const [tablefieldsToShow, setTablefieldsToShow] = useState(props.getTableFields());
  const [empNameFlag, setEmpNameFlag]=useState(true);
  const [isOpenShowHideColumnsModal, setIsOpenShowHideColumnsModal] = useState(false);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(20);
  const [sortified, setSortified] = useState(false);
  const [direction, setDirection] = useState('asc');
  const [filterCriteria, setFilterCriteria] = useState({ limit: 20, page: 1, criteria: [], sortfield: 'created', direction: 'desc' });
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsId, setSelectedRowsId] = useState();
  const [totalRecords, setTotalRecords] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedScreenPermission, setSelectedScreenPermission] = useState('');
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [apiUrl, setApiUrl] = useState(props.apiUrl);
  const [totalRecordsLength, setTotalRecordsLength] = useState(0);
  const [tabType, setTabType] = useState(props.tabType);
  const [mobileListFields, setMobileListFields] = useState([]);
  const [dateFormat, setDateFormat] = useState();
  const [selectschool, setSelectschool] = useState();
  const [roleType, setRoleType] = useState();
  const [openId, setOpenId] = useState(1);
  const [redirecToLogin, setRedirecToLogin] = useState(false);
  const [blockFamily, setBlockFamily] = useState(false);
  const [selectActions, setSelectActions] = useState();
  const [viewType, setViewType] = useState('list');
  const [actions, setActions] = useState();
  const [displayBreadCrumbField, setDisplayBreadCrumbField] = useState();
  const [displayBreadCrumbValue, setDisplayBreadCrumbValue] = useState();
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [globalSearch, setGlobalSearch] = useState();
  const [showMultiSelectDropDown, setShowMultiSelectDropDown] = useState(false);
  const [multiSelectTypes, setMultiSelectTypes] = useState();
  const [actionsTypes, setActionTypes] = useState(props.actionsTypes);
  const [userStatus, setUserStatus] = useState();
  const [selectedAutoCompleteValue, setSelectedAutoCompleteValue] = useState();
  const [suggestions, setSuggestions] = useState([]);
  const [confirmModalText, setConfirmModalText] = useState();
  const [confirmType, setConfirmType] = useState();
  const [emailTemplateData, setEmailTemplateData] = useState();
  const [redirectToRoute, setRedirectToRoute] = useState(false);
  const [searchInDateRangeField, setSearchInDateRangeField] = useState(props.searchInDateRangeField ? props.searchInDateRangeField : "created");
  const [fieldMap, setFieldMap] = useState({});
  const [formType, setFormType] = useState();
  const [page, setPage] = useState(1);
  const [sortCount, setSortCount] = useState();
  const [confirmText, setConfirmText] = useState();
  const [leaveStatus, setLeaveStatus] = useState();
  const [dailyView, setDailyView] = useState('');
  const [pendingApprovals, setPendingApprovals] = useState('');
  const [isOpenRolesModal, setIsOpenRolesModal] = useState(false);
  const [isOpenConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openBulkUploadModal, setOpenBulkUploadModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [sessionExpiryModal, setSessionExpiryModal] = useState(false);
  const [openTemplateModal, setOpenTemplateModal] = useState(false);
  const [isOpenProductsModal, setIsOpenProductsModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [displayViewOfForm, setDisplayViewOfForm] = useState(props.displayViewOfForm);
  const [openUserPasswordResetModal, setOpenUserPasswordResetModal] = useState(false);
  const [isPreviewModal, setIsPreviewModal] = useState(false);
  const [openSendEmailModal, setOpenSendEmailModal] = useState(false);
  const [tableRowData, setTableRowData] = useState({});
  const [listPreferences, setListPreferences] = useState("");
  const [saveCriteria, setSaveCriteria] = useState({ criteria: [] });
  // const [savedFiltCriteria, setSavedFiltCriteria] = useState({ criteria: [] });
  const [savedFiltCriteria, setSavedFiltCriteria] = useState({});
  const [selectedId, setSelectedId] = useState('');
  let [showReplyMailDialog, setShowReplyMailDialog] = useState(false);
  let [showBacklog,setShowBacklog]=useState(false);

  let formModalRef = useRef(null);
  const [leaveBalance, setLeaveBalance] = useState();

  const csvLinkRef = useRef();
  let dt = useRef();
  const [sortFieldForFunnel, setSortFieldForFunnel] = useState("");

  let loginRole = JSON.parse(localStorage.getItem('loginCredentials'))
  let screenPermissions = RolePermissions.screenPermissions(props.type);

  useEffect(() => {
    if(props.type=="TimeSheets" && empNameFlag==true){
      setTablefieldsToShow(prev => prev.filter(d => d.field !== "employeeName"));
    }
    readData();
    getFieldTypes();
    // getActions();
    let colOrder = localStorage.getItem(`${formatPropType(props.type)}_column_order`);
    let columns = null;
    if (colOrder != 'undefined') {
      columns = JSON.parse(colOrder);
    }
    if (columns && columns != 'undefined') {
      getTableFieldsOrder();
    } else {
      updateColumnsOrder(null);
    }
  }, [formType]);

  const readData = async () => {
    await getDataFromServer(filterCriteria);
    if (props.addSelectedRecord) {
      openFormModal('', 'add')
    } else if (props.viewSelectedRecord) {
      getActions();
      openFormModal('', 'view')
    } else if (props.editSelectedRecord) {
      openFormModal('', 'edit')
    }
  }

  const getFieldTypes = () => {
    let tableFields = props.getTableFields();
    let fieldMap = {};
    tableFields.forEach((col) => {
      // fieldMap[col.field] = col.fieldType ? col.fieldType.toLowerCase() : null;
      fieldMap[col.field] = col.fieldType ? col.fieldType.toLowerCase() : col.type == "number" ? col.type : null;

    });
    setFieldMap(fieldMap)
  }

  const getActions = async () => {
    let selRows = selectedRows ? [...selectedRows] : [];
    let actionsTypes = props.actionsTypes ? [...props.actionsTypes] : [];
    let result = [];
    if (actionsTypes && actionsTypes.length) {
      for (let i = 0; i < actionsTypes.length; i++) {
        if (actionsTypes[i]['options'] && actionsTypes[i]['options'].length > 0) {
          let options = actionsTypes[i]['options']
          for (let j = 0; j < options.length; j++) {
            if (options[j]['show'] === true) {
              if (selRows && selRows.length >= 2 && options[j]['multiple'] === true) {
                result.push({ "label": options[j]['label'], "value": options[j]['value'], "field": options[j]['field'], "action": actionsTypes[i]['action'] })
              } else if (selRows && selRows.length === 1) {
                result.push({ "label": options[j]['label'], "value": options[j]['value'], "field": options[j]['field'], "action": actionsTypes[i]['action'] })
              } else if (selRows && selRows.length === 0) {
                result.push({ "label": options[j]['label'], "value": options[j]['value'], "field": options[j]['field'], "action": actionsTypes[i]['action'] })
              }
            }
          }
        }
      }
    }
    setActionTypes(result);
  }

  const handleClickOutside = (event) => {
    if (
      closeMultiSelectDropdown.current &&
      !closeMultiSelectDropdown.current.contains(event.target)
    ) {
      setShowMultiSelectDropDown(false);
    }
  };

  const updateDimensions = async () => {
    setWidth(window.innerWidth);
    setHeight(window.height);
  };

  const getTimeFormat = () => {
    // let store=store.getState()
    // let dateFormat=store && store.commonData && store.commonData.data && store.commonData.data.timeFormat?store.commonData.data.timeFormat:'DD-MM-YYYY'
    // console.log('timeFormat',dateFormat)
  }

  const setEncryptFields = async () => {
    let encryptFields = [];
    for (let i = 0; i < tablefieldsToShow.length; i++) {
      //condition to match fieldType
      if (tablefieldsToShow[i].fieldType == "encryptedField") {
        encryptFields.push(tablefieldsToShow[i]['field']);
      }
    }
    setEncryptFields(encryptFields)
  }

  // Handle Table fields to show in datatable
  const getTableFieldsOrder = () => {
    setTablefieldsToShow([]);
    setIsLoading(true);

    let colOrder = localStorage.getItem(`${formatPropType(props.type)}_column_order`);
    let columns = JSON.parse(colOrder);
    let tempTableFields = [];
    let newTableFields = [];
    let staticTableFields = [...originalTableFields];

    if (columns) {
      for (let i = 0; i < columns.length; i++) {
        for (let j = 0; j < staticTableFields.length; j++) {
          if (columns[i].field === staticTableFields[j].field && viewType === 'list') {
            let pushItem = staticTableFields[j];
            pushItem.show = columns[i].show;
            pushItem.displayInSettings = columns[i].displayInSettings;
            tempTableFields.push(pushItem);
            break;
          }
          if (columns[i].field === staticTableFields[j].field && viewType === 'grid') {
            let pushItem = staticTableFields[j];
            pushItem.displayInSettings = columns[i].displayInSettings;
            pushItem.mobile = columns[i].mobile;
            tempTableFields.push(pushItem);
            break;
          }
        }
      }
    } else {
      tempTableFields = originalTableFields
    }

    newTableFields = tempTableFields;

    let TableFieldsToShow = [];
    if (newTableFields && newTableFields.length > 0) {
      newTableFields.map(item => {
        // TableFieldsToShow.push(item)
        if (item.show && viewType === 'list') {
          TableFieldsToShow.push(item)
        }
        if (item.mobile && viewType === 'grid') {
          TableFieldsToShow.push(item)
        }
      })
    }

    setTablefieldsToShow(TableFieldsToShow);
    setIsLoading(false);
  }

  const changeCheckIcon = async (index, subIndex, key, value) => {
    let tablefieldsToShow = tablefieldsToShow;
    if (tablefieldsToShow[index]['options'][subIndex]['checkIcon'] === true) {
      let filterCriteria = Object.assign({}, filterCriteria);
      if (filterCriteria && filterCriteria['criteria'] && filterCriteria['criteria'].length > 0) {
        let obj = filterCriteria.criteria.find(x => x.key === key);
        let objIndex = filterCriteria.criteria.indexOf(obj)
        let objValue = obj.value.find(y => y === value)
        let i = obj.value.indexOf(objValue);
        filterCriteria.criteria[objIndex]['value'].splice(i, 1);
        let length = filterCriteria.criteria[objIndex]['value'] ? filterCriteria.criteria[objIndex]['value'].length : 0;
        if (length === 0) {
          filterCriteria.criteria.splice(objIndex, 1)
        }
      }
      tablefieldsToShow[index]['options'][subIndex]['checkIcon'] = !tablefieldsToShow[index]['options'][subIndex]['checkIcon'];
      setTablefieldsToShow(tablefieldsToShow);
      setFilterCriteria(filterCriteria);
      await onMultiSelectFilterChange(key, value, 'pop', index)
    } else {
      let filterCriteria = Object.assign({}, filterCriteria);
      tablefieldsToShow[index]['options'][subIndex]['checkIcon'] = !tablefieldsToShow[index]['options'][subIndex]['checkIcon'];
      setTablefieldsToShow(tablefieldsToShow);
      if (filterCriteria && filterCriteria['criteria'] && filterCriteria['criteria'].length > 0) {
        let obj = filterCriteria.criteria.find(x => x.key === key);
        let objIndex = filterCriteria.criteria.indexOf(obj);
        await onMultiSelectFilterChange(key, value, 'push', objIndex)
      } else {
        await onMultiSelectFilterChange(key, value, 'push', index)
      }
    }
  }

  const changeViewType = async (viewType) => {
    setViewType(viewType);
    await getTableFieldsOrder()
  }

  // Handle Table fields order of display in DataTable
  const updateColumnsOrder = (currentOrder) => {
    setIsLoading(true);
    let orgTableFields = [...originalTableFields];
    let order = currentOrder ? currentOrder : null;
    let updatedOrder = [];
    let unmatchedTableFields = [...orgTableFields];

    if (order && order.length > 0) {
      order.map(async item => {
        if (item && item.props && item.props.field) {
          orgTableFields.map(col => {
            if (item.props.field === col.field) {
              updatedOrder.push(
                {
                  field: col.field,
                  show: col.show,
                  header: col.header,
                  displayInSettings: col.displayInSettings,
                  mobile: col.mobile,
                  label: col.label ? col.label : col.header
                }
              )
            }
          })
        }
      })

      order.map(async item => {
        if (item && item.props && item.props.field) {
          for (let i = 0; i < unmatchedTableFields.length; i++) {
            if (item.props.field === unmatchedTableFields[i].field) {
              unmatchedTableFields.splice(i, 1)
            }
          }
        }
      })

      if (unmatchedTableFields && unmatchedTableFields.length > 0) {
        unmatchedTableFields.map(col => {
          updatedOrder.push(
            {
              field: col.field,
              show: false,
              header: col.header,
              displayInSettings: col.displayInSettings,
              mobile: false,
              label: col.label ? col.label : col.header
            }
          )
        })
      }
    } else {
      orgTableFields.map(async col => {
        updatedOrder.push(
          {
            field: col.field,
            show: col.show,
            header: col.header,
            displayInSettings: col.displayInSettings,
            mobile: col.mobile,
            label: col.label ? col.label : col.header
          }
        )
      });
    }

    //initial storage is not required missing some fields
    /**@Fetch the listPreferences from loginCredentials */
    // let getListPreferences = JSON.parse(localStorage.getItem('loginCredentials'));
    // if (getListPreferences && getListPreferences.listPreferences) {
    //   localStorage.setItem(`${formatPropType(props.type)}_column_order`, JSON.stringify(getListPreferences.listPreferences.columnOrder[`${formatPropType(props.type)}`]));
    //   getTableFieldsOrder(orgTableFields);
    // }
  }

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let dateFormat = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.dateFormat ? storeData.settingsData.settings.dateFormat : "DD-MM-YYYY"
    if (state.dateFormat != dateFormat) {
      return { dateFormat: dateFormat };
    }
    return { dateFormat: dateFormat };

  }

  const updateSlNoToData = (itemdata, text) => {
    let modifiedData = [];
    let txt = text + 1;

    itemdata.forEach((item, index) => {
      if (props.flags) {
        let flags = props.flags
        if (item[flags['label']] == flags['value']) {
          modifiedData.push(item);
        }
      }

      else {
        // if (first) {
        //   item.Sno = (index + 1) + first;
        // } else {
        //   item.Sno = index + 1;
        // }
        item.Sno = txt;
        txt++;

        modifiedData.push(item);
      }
    });
    return modifiedData;
  }

  const updateDateFormat = (itemdata, dateFormat) => {
    let modifiedData = [];
    let tableFields = props.getTableFields();
    for (let i = 0; i < itemdata.length; i++) {
      for (let k = 0; k < tableFields.length; k++) {
        if ("Date" == tableFields[k]['fieldType']) {
          itemdata[i][tableFields[k]['field']] =
            dateFormats.formatDate(
              itemdata[i][tableFields[k]['field']],
              tableFields[k]['dateFormat'] ? tableFields[k]['dateFormat'] : dateFormat, 'tableFormat');
        }
      }
      modifiedData.push(itemdata[i])
    }
    return modifiedData;
  }

  //get table list data from server with filters if any
  const getDataFromServer = async (filterCriteria, type, dailyScreen, approvalScreen) => {
    setIsLoading(true);
    // let url = getAPIUrl();
    let url;
    if (dailyScreen === 'daily') {
      url = 'timeSheets/daily';
    } else {
      url = getAPIUrl();
    }
    setSelectedRows('')
    let dateFormat = props.dateFormat
    if (url) {
      let apiUrl;
      if (type == 'refresh') {
        // dt.reset();
        if (document.getElementById("globalSearch") && document.getElementById('globalSearch').value) {
          document.getElementById('globalSearch').value = '';
        }
        let fieldsToShow = tablefieldsToShow;
        if (fieldsToShow && fieldsToShow.length > 0) {
          for (let i = 0; i < fieldsToShow.length; i++) {
            let options = fieldsToShow[i].options;
            if (options && options.length) {
              for (let j = 0; j < options.length; j++) {
                options[j]['checkIcon'] = false
              }
            }
          }
        }
        setGlobalSearch('');
        setAllUsersData([]);
        setRoleType('');
        setTablefieldsToShow(fieldsToShow);
      }
      if (!filterCriteria || !filterCriteria['criteria']) {
        filterCriteria = { limit: 20, page: 1, criteria: [], direction: 'desc', sortfield: 'created' };
      }
      //KEEP -> PREVIOUS FILTER
      // if (filterCriteria && filterCriteria['criteria'] && filterCriteria['criteria'].length > 0) {
      //   delete filterCriteria['sortfield'];
      //   delete filterCriteria['direction'];
      // }

      if (type === 'actDel' && filterCriteria) {
        if(saveCriteria.criteria.length==0){
          filterCriteria['criteria']=savedFiltCriteria.criteria;
        }
        else{
          filterCriteria['criteria']=saveCriteria.criteria;
        }
      }

      if (type === 'refresh' && filterCriteria) {
        delete filterCriteria.globalSearch;

        if(saveCriteria.criteria.length==0){
          filterCriteria['criteria']=savedFiltCriteria.criteria;
        }
        else{
          filterCriteria['criteria']=saveCriteria.criteria;
        }
        filterCriteria['direction'] = 'desc';
        filterCriteria['sortfield'] = 'created';
      }
      if (props.params) {
        let params = props.params
        for (let i in params) {
          if (i) {
            let obj = {}
            obj['key'] = i
            obj['value'] = params[i]
            obj['type'] = 'regexOr'
            filterCriteria['criteria'].push(obj)
          }
        }
      }
      //When a screen has a default filter to be applied
      if (props.defaultCriteria) {
        if (filterCriteria && filterCriteria['criteria'] && filterCriteria['criteria'].length > 0) {
          let newFilt = filterCriteria['criteria'];
          let dc = Array.from(props.defaultCriteria);
          for (let i = 0; i < newFilt.length; i++) {
            for (let j = 0; j < dc.length; j++) {
              if (newFilt[i].key == dc[j].key) {
                dc.splice(j, 1)
              }
            }
          }
          filterCriteria['criteria'] = filterCriteria['criteria'].concat(dc);
        }
        else {
          filterCriteria['criteria'] = props.defaultCriteria
        }
      }

      //applyng filter when a row item is deleted 
      if (filterCriteria == undefined) {
        filterCriteria = { limit: 20, page: 1, criteria: [], direction: 'desc', sortfield: 'created' };
        apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}`;
      }
      if (props.filterExtension) {
        apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}&&${props.filterExtension}`
      }
      else if (props.hasQueryInCall) {
        apiUrl = `${url}&filter=${JSON.stringify(filterCriteria)}`;
      } else if (approvalScreen === 'approvals') {
        apiUrl = `${url}?type=reportingTimesheets&filter=${JSON.stringify(filterCriteria)}`
      } 
      else {
        apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}`;
      }

      let limit = filterCriteria.limit;
      let page = filterCriteria.page;

      return fetchMethodRequest('GET', apiUrl)
        .then(async (response) => {
          let apiResponseKey = props.apiResponseKey;
          let sessionexpired = await localStorage.getItem('sessionexpired')
          if (sessionexpired == "true") {
            // await setState({ sessionExpiryModal: true })
            setSessionExpiryModal(true);
          }
          let responseData = [];
          let recordsLength = 0;
          if (response && response.details && response.details.length > 0) {
            responseData = response.details;
            recordsLength = response.details.length;
          } else {
            if (response && response[apiResponseKey] && response[apiResponseKey].length && response[apiResponseKey].length >= 0) {
              if (response.pagination && response.pagination.totalCount) {
                recordsLength = response.pagination.totalCount;
              }
              responseData = updateSlNoToData(response[apiResponseKey], limit * (page - 1));
              responseData = updateDateFormat(responseData, dateFormat);
            } else {
              if (response.pagination && (response.pagination.totalCount || response.pagination.totalCount == 0)) {
                recordsLength = response.pagination.totalCount;
              }
            }
          }
          if (response && (response.leaveBalance || response.leaveBalance === 0)) {
            setLeaveBalance(response.leaveBalance);
          }
          // if (encryptFields && encryptFields.length > 0 && responseData && responseData.length > 0) {
          //   for (let field of encryptFields) {
          //     for (let data of responseData) {
          //       if (data[field]) {
          //         let decrypt = await crypto.AES.decrypt(data[field].toString(), config.sourceKey);
          //         data[field] = decrypt.toString(crypto.enc.Utf8)
          //       }
          //     }
          //   }
          //   // console.log(encryptFields)
          // }

          setAllUsersData(responseData);
          setIsLoading(false);
          if (filterCriteria.page === 1) {
            setTotalRecordsLength(recordsLength);
          }
          return responseData;
        }).catch((err) => {
          return err;
        });
    }
  }


  const getAPIUrl = () => {
    return props.apiUrl;
  }

  const getFilterUrl = (filterCriteria, type) => {
    return props.apiUrl;
  }

  //Get all data of current screen with filters applied from server to export to CSV
  const getDataToExport = async () => {
    setIsLoading(true);
    let filter = { ...filterCriteria };
    delete filter['limit'];
    delete filter['page'];
    let url = getAPIUrl();
    let apiUrl;
    apiUrl = `${url}?filter=${JSON.stringify(filter)}&type=exportToCsv`;
    return fetchMethodRequest('GET', apiUrl)
      .then(async (response) => {
        let sessionexpired = await localStorage.getItem('sessionexpired')
        if (sessionexpired == "true") {
          setSessionExpiryModal(true)
        }
        let apiResponseKey = props.apiResponseKey;
        if (response && response[apiResponseKey]) {
          let exportData = response[apiResponseKey];
          exportData.forEach((item) => {
            for (let key in item) {
              let formFields = props.formFields();
              /**@Find the Corresponding @FormField based on @key in each iteration */
              let findFormFieldBasedOnEachKey = formFields.find(formField => formField.name === key)
              if (findFormFieldBasedOnEachKey) {

                /**@HandlingMultipleAutoRelateFields */
                if (findFormFieldBasedOnEachKey.type == "relateAutoComplete" && findFormFieldBasedOnEachKey.isMultiple) {
                  if (item[key] != null && item[key].length > 0) {
                    item[key] = item[key].map(arrItem => arrItem[findFormFieldBasedOnEachKey.searchField]).toString();
                  }
                }
                /**@HandlingAutoRelateFields */
                if (findFormFieldBasedOnEachKey.type === "relateAutoComplete" && !findFormFieldBasedOnEachKey.isMultiple) {
                  if (item[key] != null && item[key][findFormFieldBasedOnEachKey.searchField]) {
                    item[key] = item[key][findFormFieldBasedOnEachKey.searchField]
                  }

                  /**@Deletx`e the Key we are using @Search Ex {Project+Search} */
                  delete item[key + "Search"];
                }
                /**@HandlingDates */
                if (findFormFieldBasedOnEachKey.type === "date") {
                  // let date = item[key].split("T")[0].split("-").reverse().join("-");
                  let date = moment(item[key]).format(findFormFieldBasedOnEachKey?.dateFormat)
                  item[key] = date;
                }
              }

              /**@DeletingUnWantedInformation Like @_id @__V @createdBy @active {MONGO FIELDS USED IN BACKEND} */
              delete item._id;
              delete item.__v;
              delete item.createdBy;
              delete item.active;
            }
          });
          setExportData(exportData);
          setIsLoading(false);
          csvLinkRef.current.link.click();
        }
      }).catch((err) => {
        setIsLoading(false);
        return err;
      });
  }

  //open Form modal
  const openFormModal = async (rowData, type) => {
    setFormType(type ? type : 'add');
    setIsOpenFormModal(true);
    setSelectedRows('');
    setSelectedId(rowData._id);
    if (type === 'view') {
      if (isOpenFormModal && formModalRef) {
        await formModalRef.handleViewDisplay(rowData, 'view');

      }
    }
    if (type == 'edit') {
      setTableRowData(rowData);
      if (isOpenFormModal && formModalRef) {
        await formModalRef.getRowData(rowData, 'edit');
      }
    }
  }

  //close form modal
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
    setRedirectToRoute(true);
    setShowReplyMailDialog(false);
  }

  const submitActionsData = async (method, url, body) => {
 
    let fc= { limit: rows, page: page, criteria: [], direction: 'desc', sortfield: 'created' };
  
    return fetchMethodRequest(method, url, body)
      .then(async (response) => {
        let sessionexpired = localStorage.getItem('sessionexpired')
        if (sessionexpired == "true") {
          setSessionExpiryModal(true);
        }
        setOpenDeleteModal(false);
        setActions('');
        setSelectedRows('');
        setSelectedRowsId('');
        if (response && response.respCode) {
          showToasterMessage(response.respMessage, 'success');
          getDataFromServer(fc,'actDel');
        } else if (response && response.errorMessage) {
          showToasterMessage(response.errorMessage, 'error');
        }
      }).catch((err) => {
        return err;
      });
  }

  const handleActions = async (apiType) => {
    let ids = selectedRows.map(id => id._id)
    // let apiUrl = apiUrl;
    let method, url, body = {}
    if (confirmType === 'Delete' || confirmType === 'Block' || confirmType === 'UnBlock') {
      url = `${apiUrl}/${apiType}`;
      method = 'POST';
      body = {
        selectedIds: ids
      }
    }
    submitActionsData(method, url, body)
  }

  // delete selected row
  const deleteSelectedRow = async () => {
    if (confirmType === 'Delete') {
      handleActions('multiDelete')
    } else if (confirmType === 'Block') {
      handleActions('multiblock?block=true')
    } else if (confirmType === 'UnBlock') {
      handleActions('multiblock?unblock=true')
    } else if (confirmType === 'ResetPassword') {
      setOpenUserPasswordResetModal(true);
      setOpenDeleteModal(false);
      setSelectedRows(selectedRows);
    }
  }

  //close delete modal
  const closeDeleteModal = async () => {
    setOpenDeleteModal(false);
    setActions('');
  }

  const setDeleteRecords = async (rowData, selectActions) => {
    let selectedRowsId = [];
    selectedRowsId.push(rowData)
    setSelectedRows(selectedRowsId);
    setConfirmType("Delete");
    deleteConfirmAction(rowData, selectActions)
  }

  // conformation for delete item
  const deleteConfirmAction = async (rowData, selectActions) => {
    let selectedRowsId = [];
    // let selectedRows = [...selectedRows]
    if (selectedRows && selectedRows.length && selectedRows.length > 0) {
      selectedRows.forEach((item, index) => {
        selectedRowsId.push(item._id)
      })
      setSelectedRowsId(selectedRowsId);
      setOpenDeleteModal(true);
      setSelectActions(selectActions);
    }
    if (rowData) {
      setDeleteRowDataId(rowData);
      setOpenDeleteModal(true);
      setSelectActions(selectActions);
    }
  }

  //change dropdown elememt
  const changeDropDownElement = (event) => {
    dt.filter(event.target.value, event.target.name, 'equals');
    //************ Check for solution
  }

  const getTableFieldItem = (field) => {
    for (let i = 0; i < tablefieldsToShow.length; i++) {
      if (tablefieldsToShow[i].field == field) {
        return tablefieldsToShow[i];
      }
      //condition to match fieldType
      if (tablefieldsToShow[i].fieldType == field) {
        return tablefieldsToShow[i]['field'];
      }
    }
    return null;
  }

  const getColorFromOptions = (options, name) => {
    if (options) {
      for (let i = 0; i < options.length; i++) {
        if (options[i].value == name) {
          return options[i].color;
        }
      }
    }
    if (props.type == "Employees") {
      if (name && typeof (name) == "string") {
        let n = name.toLowerCase();
        if (n == "admin") {
          return 'primary';
        }
        if (n == "director" || n == "hr" || n == "teamlead") {
          return 'success';
        }
        if (n == "employee" || n == "qa" || n == "trainee" || n == "developer") {
          return 'warning';
        }
      }
    }

    return 'success';
  }

  const getUserData = (_id, type) => {
    let data = allUsersData;
    for (let i = 0; i < data.length; i++) {
      if (data[i]['_id'] === _id) {
        return i
      }
    }
  }

  const getIconValue = (rowData, labelKey) => {
    if (labelKey && labelKey.options && labelKey.options.length > 0) {
      for (let i = 0; i < labelKey.options.length; i++) {
        if (labelKey.options[i].value === rowData[labelKey.field]) {
          return labelKey.options[i].displayText;
        }
      }
    }
    return '';
  }

  const getAUserData = (_id, type) => {
    let data = allUsersData;
    for (let i = 0; i < data.length; i++) {
      if (data[i]['_id'] === _id) {
        return data[i]
      }
    }
  }

  const onOpenViewModal = async (rowData, type, from) => {
    let rowDataIndex = getUserData(rowData['_id'], '_id');
    if (from == "route") { rowData = getAUserData(rowData['_id'], '_id') }
    let _id = rowData['_id'];
    let status = rowData['status'] ? rowData['status'] : ''
    let keys = Object.keys(rowData);

    let formFields = [];
    if (formFields) {
      if (props.type) {
        if (rowData) {
          let values, fieldType, searchField, self = this, icon = false;

          // hari get all the labels from 
          keys.forEach(async function (key) {
            let labelKey = await self.getTableFieldItem(key);
            if (labelKey == null) {
              labelKey = key;
            } else {
              let val = rowData[key];
              if (labelKey.fieldType === 'icon') {
                val = self.getIconValue(rowData, labelKey);
                icon = true;
              }
              fieldType = labelKey.fieldType ? labelKey.fieldType : null
              searchField = labelKey.searchField ? labelKey.searchField : null
              let options = labelKey.options ? labelKey.options : []
              labelKey = labelKey.header
              if (val) {
                if (fieldType && searchField && fieldType == "relateAutoComplete") {
                  values = {
                    label: labelKey,
                    value: icon ? val : rowData[key][searchField],
                    fieldType: fieldType
                  }
                } else {
                  values = {
                    label: labelKey,
                    value: icon ? val : rowData[key],
                    fieldType: fieldType,
                    options: options
                  }
                }
                formFields.push(values);
                icon = false;
              }
            }
            // if (key == 'fromTime' || key == 'toTime') {
            //   let date = dateFormats.addDaysToDate(rowData[key], 1);
            //   date = dateFormats.formatDate(date, config.timeFormat);
            //   values = {
            //     label: key,
            //     value: date
            //   }
            //   formFields.push(values);
            // }
          });
        }
      }
      if (displayViewOfForm === 'modal') {
        setIsOpenFormModal(true);
        setUserStatus(status);
        setFormType(type);
        // await viewModalRef.getRowData(formFields);
        await formModalRef.getViewData(formFields, 'view', rowDataIndex, rowData, _id);
      } else if (displayViewOfForm === 'screen') {
        let displayBreadCrumbField = getTableFieldItem('Link');
        setIsOpenFormModal(true);
        setUserStatus(status);
        setFormType(type);
        setDisplayBreadCrumbField(displayBreadCrumbField);
        setDisplayBreadCrumbValue(rowData[displayBreadCrumbField])

        // await formModalRef.getViewData(formFields, 'view', rowDataIndex, rowData, _id);
      }
    }
    await getDataFromServer();

  }

  const closeViewModal = async () => {
    setOpenViewModal(false);
  }

  //on changing pagination
  const onPageChange = async (event) => {
    let filter = { ...filterCriteria };
    if (event && event.rows) {
      let currentPage = event.page + 1;
      filter['limit'] = event.rows;
      filter['page'] = currentPage;
      filter['criteria'] = saveCriteria.criteria;
      if (Object.keys(savedFiltCriteria).length !== 0) {
        filter['criteria'].push(...savedFiltCriteria.criteria);
      }
      setRows(event.rows);
      setFirst(event.first);
      setPage(event.page + 1);
    }
    setFilterCriteria(filter)
    getDataFromServer(filter, '', dailyView, pendingApprovals)
  }

  const onPageChangeAccordion = async (event) => {
    let filterCriteria = filterCriteria;
    let id = openId;
    if (event && event.rows) {
      let currentPage = event.page + 1;
      filterCriteria['limit'] = event.rows;
      filterCriteria['page'] = currentPage;
    }
    // getDataFromServer(filterCriteria)
  }

  //sorting fields
  const sortChange = (event) => {
    let initialTableFields = props.getTableFields();
    initialTableFields.forEach(key => {
      if (key.field && key.field === event.sortField) {
        if (props.type === 'TimeSheets' && event.sortField === 'employee') {
          event.sortField = key.field + "Name";
        } else if (props.type === 'Tasks' && event.sortField === 'assignedTo') {
          event.sortField = key.field + "Search";
        } else if (key.fieldType === 'relateAutoComplete') {
          event.sortField = key.field + "Search";
        } else {
          return event
        }
      }
    });

    setSelectedRows('');
    let sort = sortCount;
    let criteria = filterCriteria
    if (event && event['sortField']) {
      sort = sort == 0 ? sort + 1 : 0;
      let sortField = event['sortField'];
      criteria = {
        criteria: saveCriteria.criteria,
        direction: sort == 0 ? "desc" : 'asc',
        sortfield: sortField,
        limit: rows,
        page: page ? page : 1,
      }

      setIsLoading(true)
      setSortCount(sort);
      setFilterCriteria(criteria);
      getDataFromServer(criteria, '', dailyView, pendingApprovals);
    }
  }

  const confirmActionType = async (type) => {
    if (type === 'Delete') {
      setConfirmType(type);
      setConfirmModalText('Are you sure want to Delete')
      deleteConfirmAction()
    } else if (type === 'Block') {
      setConfirmType(type);
      setConfirmModalText('Are you sure want to Block')
      deleteConfirmAction()
    } else if (type === 'ResetPassword') {
      setConfirmType(type);
      setConfirmModalText('Are you sure want to Block');
      setOpenUserPasswordResetModal(true);
      setOpenDeleteModal(false);
      setSelectedRows(selectedRows);
      // deleteConfirmAction()
    } else if (type === 'Send Email') {
      setOpenSendEmailModal(true);
      // deleteConfirmAction()
    }
  }

  //onActionsChange
  const onActionsChange = async (event, type) => {
    if (type == 'dropdownFilter') {
      for (let i in actionsTypes) {
        if (actionsTypes[i].value === event.target.value) {
          if (actionsTypes[i].action) {

            let filter = { ...filterCriteria };
            filter['limit'] = rows;
            filter['page'] = page;
            filter['criteria'] = saveCriteria.criteria;

            actionsTypes[i].action(selectedRows, actionsTypes[i].field, actionsTypes[i].value)
            getDataFromServer(filter, '', dailyView, pendingApprovals);
            return;
          }
        }
      }
      confirmActionType(event.target.value)
    }
  }

  const rowFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={representatives}
        itemTemplate={representativesItemTemplate}
        onChange={(e) => options.filterApplyCallback(e.value)}
        optionLabel="name"
        placeholder="Any"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: '14rem' }}
      />
    );
  };

  const selectFilterTemplate = (options) => {
    return (
      <Dropdown value={options.value} options={statuses} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={statusItemTemplate} placeholder="Select One" className="p-column-filter" showClear style={{ minWidth: '12rem' }} />
    );
  };

  const onMultiSelectFilterChange = async (key, value, type, index) => {
    let filterCriteria = filterCriteria;
    if (filterCriteria && type === 'push') {
      let v = [], length = '';
      if (filterCriteria['criteria'] && filterCriteria['criteria'][index] && filterCriteria['criteria'][index]["value"]) {
        v = [...filterCriteria['criteria'][index]["value"]];
        length = v.length;
        v[length] = value;
        filterCriteria['criteria'][index]["value"] = v;
      } else {
        v[0] = value
        filterCriteria['criteria'].push({
          key: key,
          value: v,
          type: 'in'
        });
      }

      setFilterCriteria(filterCriteria)
      await getDataFromServer(filterCriteria)
    } else {
      getDataFromServer(filterCriteria)
    }
  }

  const getCriteriaOfSavedFilt = (jsonq) => {
    setSavedFiltCriteria(jsonq);
  }

  const onFilterColumns = (event) => {
    let res = FunnelFilter(event, fieldMap, "c", sortCount, sortFieldForFunnel, props.type, rows, saveCriteria.criteria, savedFiltCriteria);
    console.log("filterCriteria", filterCriteria.criteria)
    console.log("saveCRiteria", saveCriteria.criteria)
    setSaveCriteria(res);
    getDataFromServer(res, '', dailyView, pendingApprovals);

    //KEEP -> PREVIOUS FILTER
    // let filterCriteria = { limit: 20, page: 1, criteria: [], sortfield: 'created', direction: 'desc' }
    // console.log(event);
    // if (event && event.filters) {
    //   let filters = { ...event.filters };
    //   for (const field in filters) {
    //     if (filters[field] && filters[field].value) {
    //       if (fieldMap[field] === 'date') {
    //         let dateFilters = formatDateFilters(filters[field], field);
    //         dateFilters.forEach((dateFilter) => {
    //           filterCriteria.criteria.push(dateFilter);
    //         });
    //       } else if (fieldMap[field] === 'dropdown') {
    //         if (filters[field].value.length > 0) {
    //           filterCriteria.criteria.push({ key: field, value: filters[field].value, type: 'in' });
    //         }
    //       } 
    //       else if (fieldMap[field] === 'radio') {
    //         // console.log("onFilterColumns=radio")
    //         if (filters[field].value.length > 0) {
    //           filterCriteria.criteria.push({ key: field, value: filters[field].value, type: 'in' });
    //         }
    //       } 
    //       else if (fieldMap[field] === 'number') {
    //        if (filters[field].value.length > 0) {
    //          filterCriteria.criteria.push({ key: field, value: filters[field].value, type: 'eq' });
    //        }
    //      } 
    //     else if(fieldMap[field] == "relateautocomplete"){
    //       if (filters[field].value.length > 0) {
    //         filterCriteria.criteria.push({ key: field+"Search", value: filters[field].value, type: 'regexOr' });
    //       }
    //     }
    //       else {
    //         filterCriteria.criteria.push({ key: field, value: filters[field].value, type: 'regexOr' });
    //       }
    //     }
    //   }
    // }
    setFilterCriteria(res);
    // setSaveCriteria(filterCriteria);
    // getDataFromServer(filterCriteria);

  }

  const getOneDayQuery = (date) => {
    let todayDate = moment(new Date(date)).format('YYYY-MM-DD');
    return { $lte: new Date(todayDate + 'T23:59:59Z'), $gte: new Date(todayDate + 'T00:00:00Z') };
  }

  const getPreviousDate = (inputDate) => {
    if (inputDate) {
      const date = new Date(inputDate);
      date.setDate(date.getDate() - 1);

      // Format the date in 'YYYY-MM-DD' format
      const formattedDate = date.toISOString().split('T')[0];

      return formattedDate;
    } else {
      return null; // or handle the case where date is not provided
    }
  };

  const getNextDate = (inputDate) => {
    if (inputDate) {
      const date = new Date(inputDate);
      date.setDate(date.getDate() + 1);

      // Format the date in 'YYYY-MM-DD' format
      const formattedDate = date.toISOString().split('T')[0];

      return formattedDate;
    } else {
      return null; // or handle the case where date is not provided
    }
  };

  const formatDateFilters = (filterObj, field) => {
    if (filterObj.value && filterObj.value.length === 2) {
      if (filterObj.value[1] !== null) {
        const start = dateFormats.formatDate(filterObj.value[0], config.dayYearDateFormat);
        const initialDate = getPreviousDate(start);
        const inputDateForNext = dateFormats.formatDate(filterObj.value[1], config.dayYearDateFormat);
        const nextDate = getNextDate(inputDateForNext);
        return [
          { key: field, value: initialDate, type: 'gte' },
          { key: field, value: nextDate, type: 'lt' }
        ];
      } else {
        const inputDate = dateFormats.formatDate(filterObj.value[0], config.dayYearDateFormat);
        const previousDate = getPreviousDate(inputDate);
        let date = getOneDayQuery(previousDate);
        return [{ key: field, value: previousDate, type: 'gte' }, { key: field, value: inputDate, type: 'lte' }]

      }
    } else {
      return [];
    }
  }

  // on search get data from server
  const onFilterChange = async (event, type) => {
    setIsLoading(true);
    setSelectedRows('');
    if (type === 'dropdownFilter' || type === 'date') {
    }
    let fieldName = '', filter = { ...filterCriteria },
      selectedFilterValue, selectedFilter, selecterFilterType, formattedTime, formattedDate, isDateFilter = false;
    if (event) {
      if (event.filters && !type) {
        if (Object.keys(event.filters) && Object.keys(event.filters)[0]) {
          fieldName = Object.keys(event.filters)[0];
        }
        let field = event.filters;
        selectedFilter = field[fieldName];
        if (fieldName == 'date' || fieldName == 'created' || fieldName == 'updated') {
          isDateFilter = true;
          selectedFilterValue = selectedFilter && selectedFilter.value && selectedFilter.value.length == 10 ?
            selectedFilter.value : null;
          let date = dateFormats.addDaysToDate(selectedFilter.value, 1);
          formattedDate = dateFormats.formatDate(date, config.dayYearDateFormat);
          selecterFilterType = 'eq'
        } else {
          selecterFilterType = 'regexOr'
          selectedFilterValue = selectedFilter && selectedFilter.value ? selectedFilter.value : null;
        }
      } else {
        fieldName = event.target.name;
        selectedFilterValue = event && event.target && event.target.value ? event.target.value : null;
        setGlobalSearch(selectedFilterValue);
      }
      if (type == 'dropdownFilter') {
        selecterFilterType = 'in'
      }
      if ((type == 'dropdownFilter' && selectedFilterValue && selectedFilterValue.length && selectedFilterValue.length > 0) || (type !== 'date' && selectedFilterValue && selectedFilterValue.length && selectedFilterValue.length >= 3)) {
        if (fieldName == 'createdBy') {
          fieldName = 'createdBy.name';
        }
        if (fieldName == 'phone') {
          fieldName = 'phone';
        }
        if (fieldName == 'globalSearch') {
          filter.globalSearch = {
            value: selectedFilterValue,
            type: 'user'
          }
          if (event.target.value.length == 0) {
            delete filter.globalSearch;
          }
        } else {
          if (selecterFilterType == 'gte') {
            let obj = filter.criteria.find(x => x.key == fieldName);
            let index = filter.criteria.indexOf(obj);
            if (index != -1) {
              filter['criteria'].splice(index, 1, {
                key: fieldName,
                value: formattedDate,
                type: 'eq'
              });
            } else {
              filter['criteria'].push({
                key: fieldName,
                value: formattedDate,
                type: 'eq'
              });
            }
          } else {
            if (fieldName == 'updated') {
              fieldName = 'updated.name'
            }
            if (filter['criteria'].length == 0 && selecterFilterType != 'lte') {
              filter['criteria'].push({
                key: fieldName,
                value: selectedFilterValue,
                type: selecterFilterType
              });
            } else {
              let obj = filter.criteria.find(x => x.key == fieldName);
              let index = filter.criteria.indexOf(obj);
              if (selecterFilterType == 'lte') {
                if (selectedFilterValue.length == 10) {
                  filter['criteria'].splice(0, 1, ({
                    key: fieldName,
                    value: selectedFilterValue.substring(6, 10) + '-' + selectedFilterValue.substring(3, 5) + '-' + selectedFilterValue.substring(0, 2) + 'T23:59:59Z',
                    type: selecterFilterType
                  }));
                }
              }
              if (index != -1 && selecterFilterType != 'lte') {
                filter['criteria'].splice(index, 1, {
                  key: fieldName,
                  value: selectedFilterValue,
                  type: selecterFilterType
                });
              } else if (selecterFilterType != 'lte') {
                filter['criteria'].push({
                  key: fieldName,
                  value: selectedFilterValue,
                  type: selecterFilterType
                });
              }
            }
          }
          setFilterCriteria(filter);
        }

        await getDataFromServer(filter)
      } else if (type === 'date' && selectedFilterValue && selectedFilterValue.length && selectedFilterValue.length > 0) {
        if (selectedFilterValue.length == 2) {
          let fieldIndex1 = filter.criteria.findIndex((obj) => obj.key == fieldName)
          let fieldIndex2 = filter.criteria.findIndex((obj) => obj.key == fieldName)
          if (fieldIndex1 >= 0) filter.criteria.splice(fieldIndex1, 1)
          if (fieldIndex2 >= 0) filter.criteria.splice(fieldIndex2, 1)
          let startDate = (moment(selectedFilterValue[0]).format('YYYY-MM-DD'));
          let eDate = selectedFilterValue[1] ? JSON.parse(JSON.stringify(selectedFilterValue[1])) : JSON.parse(JSON.stringify(selectedFilterValue[0]))
          eDate = new Date(eDate)
          let endDate = eDate.setDate(eDate.getDate() + 1);
          endDate = (moment(endDate).format('YYYY-MM-DD'));
          filter['limit'] = 20;
          filter['page'] = 1;
          filter.criteria.push({ 'key': fieldName, 'value': startDate, 'type': 'gte' })
          filter.criteria.push({ 'key': fieldName, 'value': endDate, 'type': 'lte' })
        }
        // await setState({
        //   filterCriteria: filterCriteria
        // });
        setFilterCriteria(filter);
        await getDataFromServer(filter)
      } else {
        if ((selectedFilterValue == null || (type === 'dropdownFilter' && selectedFilterValue.length == 0)) && !isDateFilter) {
          let obj = filter.criteria.find(x => x.key == fieldName);
          let index = filter.criteria.indexOf(obj);
          filter.criteria.splice(index, 1);
          if (fieldName == 'globalSearch') {
            filter.globalSearch = {}
            delete filter.globalSearch
          }
          // await setState({
          //   filterCriteria: filterCriteria
          // });
          setFilterCriteria(filter);
          await getDataFromServer(filter)
        }
      }
      // setState({
      //   isLoading: false
      // })
      setIsLoading(false);
    }
  }

  //open Bulk Modal
  const bulkUploadMoadal = () => {
    setOpenBulkUploadModal(true);
  }

  //close Bulk Modal
  const closeBulkModal = async () => {
    setOpenBulkUploadModal(false);
  }

  //select multiple rows to delete
  const onSelectRowsUpdate = async (event) => {
    if (isOpenFormModal && event.value.length > 0) {
      setSelectedRows([event.value[event.value.length - 1]])
    }
    else {
      setSelectedRows(event.value);
      await getActions()
    }
  }

  //openConfirmationModal
  const openConfirmationModal = async (rowData, status, type) => {
    setIsOpenFormModal(false);
    setOpenConfirmationModal(true);
    setItem(rowData);
    setConfirmText(type);
    setLeaveStatus(status);
  }

  const closeConfirmationModal = () => {
    setOpenConfirmationModal(false);
  }

  const setFilterCriteriaForActivities = async (editRowDataID) => {
    let filterCriteria = filterCriteria;
    filterCriteria['criteria'].push(
      { key: 'contextId', value: editRowDataID, type: 'eq' }
    )
    setFilterCriteria(filterCriteria);
  }

  const getTooltipFromOtptions = (options, name) => {
    if (options) {
      for (let i = 0; i < options.length; i++) {
        if (options[i].value === name) {
          return options[i].tooltip;
        }
      }
    }
    return '';
  }

  const getArrayBadges = (badge_values) => {
    return Array.isArray(badge_values) ? badge_values : [];
  }

  //change table body values//
  // hari need to move to derived class or controller
  const changeFieldValues = (item, column) => {
    let self = this, tableItem;
    tableItem = getTableFieldItem(column.field);

    if (tableItem.fieldType == "Link") {
      return <div className='textElipses'>
        <div onClick={() => openFormModal(item, 'view')} style={tableItem.style} title={item[column.field]}>
          {item[column.field]}
        </div >
      </div >
    }
    if (tableItem.isClickable && tableItem.isClickable == true) {
      return <div className='textElipses'>
        <span onClick={() => openFormModal(item, 'view')} title={item[column.field]}>
          {item[column.field]}
        </span >
      </div >
    }
    if (tableItem.fieldType == 'image' && !Array.isArray(item[column.field])) {
      return <div className='textElipses'>
        {item && item.image ?
          <Link to={`${config.imgUrl}${props.type.toLowerCase()}/${item.image}`} target="_blank" style={{ textDecoration: "none" }}>{item[column.field]}</Link> : null
        }
      </div>
    }
    if (tableItem.fieldType == 'image') {
      return <div className='textElipses'>
        {item && item[column.field] && item[column.field].length > 0 ?
          <Link to={`${config.imgUrl}${props.type.toLowerCase()}/${item[column.field][0]}`} target="_blank" style={{ textDecoration: "none" }}>{item[column.field][0]}</Link> : null
        }
      </div>
    }
    if (tableItem.fieldType == 'driveLink') {
      return <div>
        {item && item[column.field] ?
          <a href={`${item[column.field]}`} target="_blank" style={{ textDecoration: "none" }}>{item[column.field]}</a> : null
        }
      </div>
    }
    if (tableItem.isClickable && tableItem.isClickable == true && tableItem.fieldType != "relateAutoComplete") {
      return <div className='textElipses'>
        <span
          // onClick={() => openViewModal(item, 'view')}
          title={item[column.field]}>
          <a href={`/view_${props.routeTo}/${JSON.stringify(item._id)}`} style={{ textDecoration: "none" }}>  {item[column.field]}</a>
        </span >
      </div >
    }
    else if (tableItem.type === "time") {
      const timestamp = item[column.field];
      if (timestamp) {
        const date = new Date(timestamp);
        let hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        let period = 'AM';

        if (hours >= 12) {
          period = 'PM';

          // Convert 12-hour format to 12 AM/PM format
          if (hours > 12) {
            hours -= 12;
          }
        }
        // Format the time as "hh:mm AM/PM"
        const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes} ${period}`;
        return <span style={tableItem.style} title={formattedTime}>
          {formattedTime}
        </span>;
      }
    }
    else if (tableItem.fieldType == "WebSite") {
      return <div className='textElipses'>
        <a href={item[column.field]} title={item[column.field]} target="_blank">{item[column.field]}</a>
      </div >
    } else if (tableItem.fieldType == "Download") {
      return <div className='textElipses'>
        <a href={item["duplicateFileDownloadUrl"]} title={item[column.field]} target="_blank">{item[column.field]}</a>
      </div >
    } else if (!tableItem.isMultiSelect && tableItem.fieldType == "dropDown") {
      let mcolor = getColorFromOptions(tableItem.options, item[column.field]);

      return<CustomBadge  val={item[column.field]}  color={mcolor} />
 
    }
    else if (tableItem.isMultiSelect && tableItem.fieldType == "dropDown") {
      let badgeValues = getArrayBadges(item[column.field]);
      if (badgeValues.length === 0) {
        return null;
      }
      let badges = badgeValues.map((value, index) => {
        let mcolor = getColorFromOptions(tableItem.filterElement, value);
        return<CustomBadge  val={value}  color={mcolor} />
      });
      return <div>{badges}</div>;
    }
    else if (tableItem.fieldType == "Badge") {
      let mcolor = getColorFromOptions(tableItem.options, item[column.field]);
      return<CustomBadge  val={item[column.field]}  color={mcolor} />
    } else if (tableItem.fieldType == "Role") {
      let mcolor = getColorFromOptions(tableItem.options, item[column.field]);
      return<CustomBadge  val={item[column.field]}  color={mcolor} />
    } else if (tableItem.fieldType === "icon") {
      let mcolor = getColorFromOptions(tableItem.options, item[column.field]);
      let tooltip = getTooltipFromOtptions(tableItem.options, item[column.field]);
      return <FontAwesomeIcon
        style={{ color: mcolor, cursor: tooltip ? 'pointer' : '' }}
        color='white'
        icon={tableItem.iconName}
        data-toggle="tool-tip"
        title={tooltip}
      />
    } else if (tableItem.fieldType === "Array") {
      let val = flattenArray(item[column.field]);
      return <span style={tableItem.style} title={val}>
        {val}
      </span>
    } else if (tableItem.fieldType === "Complex") {
      let data = ObjectbyString(item, column.field)
      return <span>
        {data}
      </span>
    } else if (tableItem.fieldType == "Actions") {
      let arr = tableItem.customFun ? tableItem.customFun(item) : ['edit', 'delete', 'preview']
      return (
        <div className='row'
          style={{ justifyContent: 'center' }}>
          <div>
            {(props.preview && arr.includes('preview')) ? <FontAwesomeIcon
              className='genderIconAlignment'
              color='#17a2b8'
              icon='file'
              data-toggle="tool-tip"
              title='Preview'
              style={{ color: '#17a2b8', width: '15', cursor: 'pointer', marginRight: 9 }}
              onClick={() => openPreviewModal(item)} /> : ''}
            {(props.customLeavesButton) ?
              props.customLeavesButton(item, getDataFromServer, filterCriteria)
              : null}

            {((props.editRequired && arr.includes('edit') && props.type !='Releases') && 
              (!(["TimeSheets"].includes(props.type) && loginRole.roleType == "HR" && loginRole._id !== item.employeeId ))
            ) ?
              <FontAwesomeIcon
                className='genderIconAlignment'
                color='white'
                icon='edit'
                data-toggle="tool-tip" title="Edit"
                style={{ color: '#024a88', width: '15', cursor: 'pointer' }}
                onClick={() => openFormModal(item, 'edit')} /> 
 
            : ''}
            {(props.editRequired && arr.includes('edit') && props.type =='Releases' && loginRole.role?.toLowerCase() == "qa") ?
             
              <FontAwesomeIcon
                className='genderIconAlignment'
                color='white'
                icon='paper-plane'
                data-toggle="tool-tip"
                title="Send Reply Mail"
                style={{ color: '#024a88', width: '15px', cursor: 'pointer' }}
                onClick={() => {
                    setShowReplyMailDialog(true);
                    openFormModal(item, 'edit')                  
                }}
              />

            : ''}
            {(props.type !='Releases' && props.deleteRequired && arr.includes('delete')) ?
              <FontAwesomeIcon
                className='genderIconAlignment'
                color='white'
                icon='trash-alt'
                data-toggle="tool-tip"
                title="Delete"
                style={{ color: '#bf1725', width: '13', marginLeft: 10, cursor: 'pointer' }}
                onClick={() => setDeleteRecords(item, "Delete")} />
              : null}
            {(props.customActionButton) ?
              props.customActionButton(item, getDataFromServer, filterCriteria)
              : null}
          </div>
        </div >
      )
    } else if (tableItem.fieldType == "relateAutoComplete") {
      if (tableItem.isMultiple) {
        let data = ""
        if (tableItem.searchField && item[column.field] && item[column.field].length > 0) {
          for (let i = 0; i < item[column.field].length; i++) {
            let obj = item[column.field][i];
            data += obj[tableItem.searchField];

            if (i < item[column.field].length - 1) {
              data += ", ";
            }
          }
        }
        return <div style={tableItem.style} title={data} >
          {data}
        </div >
      } else {
        return tableItem.searchField && item[column.field] && item[column.field][tableItem.searchField] ?
          <div style={tableItem.style} title={item[column.field][tableItem.searchField]} >
            {item[column.field][tableItem.searchField]}
          </div >
          : null;
      }

    } else if (tableItem.fieldType == "RACSubField") {
      return tableItem.parentField && item[tableItem.parentField] && item[tableItem.parentField][tableItem.subFieldName] ?
        <span style={tableItem.style} title={item[tableItem.parentField][tableItem.subFieldName]} >
          {item[tableItem.parentField][tableItem.subFieldName]}
        </span >
        : null;
    } else if (column.field == 'hours') {
      if (props.type == 'TimeSheets') {
        if (dailyView == 'daily') {
          return item.timesheetHours;
        }
        else {
          return item[column.field];
        }
      }
    } else if (column.field == 'afterLeaves' && props.type == 'LeaveHistories') {
      if (item && item.leaveBalance && (item.leaveBalance.afterLeaves || item.leaveBalance.afterLeaves === 0)) {
        return item.leaveBalance.afterLeaves;
      }
    } else if (tableItem.type === 'ckeditor' && item[column.field]) {
      let updatedValue = isUpdateEditorValues(item[column.field]);
      return (
        <LightTooltip title={updatedValue}>
          <div style={tableItem.style}>{updatedValue}</div>
        </LightTooltip>
      );
    }
    else {
      if ((item[column.field]) && typeof item[column.field] !== 'object') {
        return <div style={tableItem.style} title={item[column.field]} >
          {item[column.field]}
        </div >
      } else {
        if(column.field === 'totalLeaves' && item[column.field] == 0) {
          return<span>{"0"}</span>
        }
      }
    }
  }

  const isUpdateEditorValues = (values) => {
    values = values.replace(/-/g, '');
    const tempElement = document.createElement('div');
    tempElement.innerHTML = values;
    return (tempElement.textContent.replace(/<p>/gi, ' ').replace(/<\/p>/gi, ' '));

  }

  const clickConfirm = () => {
    closeConfirmationModal();
  }

  const ObjectbyString = (o, s) => {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
      var k = a[i];
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    }
    return o;
  }

  const rowClassName = (item) => {
    if (props.type == 'PublicHolidays') {
      if (item.day == 'Sunday' || item.day == 'Saturday') {
        return (
          { 'p-highlightHoliday': true }
        )
      }
    }
    if (props.type == 'Attendences' || props.type == 'TimeSheets') {
      if (item.isHoliday === true || item.isWeekEnd === true) {
        return ({ 'p-highlightHoliday': true })
      }
    }
    if (props.type == 'TimeSheets') {
      if (item && (item.createdByName && item.employeeName)) {
        if (item.employeeName !== item.createdByName) {
          return ({ 'p-highlightOthersCreated': true })
        }
      }
    }
    if (props.type == 'Attendences') {
      if (item && item.workingHours < 6) {
        return ({ 'p-highlightLessThan6Hrs': true })
      }
      if (item && item.workingHours == 0) {
        return ({ 'p-highlightForZeroHours': true })
      }
    }
  }

  const openPreviewModal = async (item) => {
    setIsPreviewModal(true);
    setEmailTemplateData(item ? item : item);
  }

  const closePreviewModal = () => {
    setIsPreviewModal(false);
    setEmailTemplateData('');
  }

  const closeSendEmailModal = () => {
    setOpenSendEmailModal(false);
    setActions('');
  }

  const handleDateInterval = (startDate, endDate) => {
    if (startDate) {
      setStartDate(startDate);
    }
    if (endDate) {
      setEndDate(endDate);
    }
  }

  // ShowHideColumns Button Click Handler
  //Settings Modal open
  const openShowHideColumnsModal = () => {
    setIsOpenShowHideColumnsModal(true);
  }

  const getSettings = async () => {
    props.getSettings();
  }

  // Called When Clicked on Cancel or Confirm in ShowHideColumnsModal
  const closeShowHideColumnsModal = async (type, columnFields, changedTableFields) => {
    if (type && type === 'confirm' && columnFields) {
      let updatedOrder = [];
      let fields = [...changedTableFields];

      setIsOpenShowHideColumnsModal(false);
      if (viewType === 'grid') {
        fields.map(async item => {
          Object.entries(columnFields).forEach(async ([key, value]) => {
            if (item.field === key) {
              return item.mobile = value;
            }
          });
        })
      }
      if (viewType === 'list') {
        fields.map(async item => {
          Object.entries(columnFields).forEach(async ([key, value]) => {
            if (item.field === key) {
              return item.show = value;
            }
          });
        })
      }
      fields.map(async col => {
        updatedOrder.push(
          {
            ...col,
            field: col.field,
            show: col.show,
            header: col.header,
            displayInSettings: col.displayInSettings,
            mobile: col.mobile,
            label: col.label ? col.label : col.header
          }
        )
      });

      /**GetListPreferenceId */
      let employeeId = JSON.parse(localStorage.getItem('loginCredentials'));
      let setPreferenceValue = {};
      setPreferenceValue[formatPropType(props.type)] = updatedOrder;
      fetchMethodRequest("PUT", `listPreferences/${employeeId.listPreferences ? employeeId.listPreferences._id : undefined}`, setPreferenceValue)

      localStorage.setItem(`${formatPropType(props.type)}_column_order`, JSON.stringify(updatedOrder));
      await getTableFieldsOrder(originalTableFields);
    } else if (type && type === 'confirm') {
      let fields = [...changedTableFields];
      setIsOpenShowHideColumnsModal(false);
      localStorage.setItem(`${formatPropType(props.type)}_column_order`, JSON.stringify(fields));

      await getTableFieldsOrder(originalTableFields);
    } else {
      setIsOpenShowHideColumnsModal(false);
    }
  }

  const searchInDateRange = () => {
    if (startDate && endDate) {
      let filterCriteria = {};
      let startDate = (moment(startDate).format(config.dateDayMonthFormat));
      let endDate = (moment(endDate).format(config.dateDayMonthFormat));
      filterCriteria['limit'] = 20;
      filterCriteria['page'] = 1;
      filterCriteria['criteria'] = [
        {
          'key': 'date',
          'value': startDate,
          'type': 'gte'
        },
        {
          'key': 'date',
          'value': endDate,
          'type': 'eq'
        }];
      setFilterCriteria(filterCriteria);
      getDataFromServer(filterCriteria);
    } else {
      alert('no dates selected');
    }
  }

  const toggle = async (id) => {
    let opId = openId === id ? '' : id;
    setOpenId(opId);
  }

  const print = () => {
    window.print();
  }

  const toogleMultiSelect = async () => {
    setShowMultiSelectDropDown(!showMultiSelectDropDown);
  }

  const onGlobalSearchChange = async (e) => {
    let suggestions = suggestions;
    suggestions[0] = { "label": `Search By First Name ${e.query}` }
    setSuggestions(suggestions);
  }

  //KEEP-now asked to remove, might need later
  // const myTaskFilters = async () => {
  //   let filterValues = ["new", "in development", "reopen", "need clarification", "analysis/design"]
  //   let filterCriteria = {
  //     limit: rows,
  //     page: page,
  //     criteria: saveCriteria.criteria,
  //   }
  //   let details = JSON.parse(localStorage.getItem('loginCredentials'))

  //   filterCriteria.criteria.push(
  //     { "key": "taskStatus", "value": filterValues, "type": "in" },
  //     { "key": "assignedToName", "value": details.displayName, "type": "regexOr" }
  //   );

  //   setFilterCriteria(filterCriteria)
  //   getDataFromServer(filterCriteria)
  // }

  const getBacklogTasks =async () => {
    setShowBacklog(true);
    let fullFilt=filterCriteria
    let cri=fullFilt.criteria;
    cri.push({key:"sprintName", type:"nexists"},{key:"taskStatus", value:["new","in development","dev completed","analysis/design","review completed","ready for testing","need clarification","reopen","onhold","future implementation"], type:"in"})
    let filtcri={ limit: rows, page: page, criteria: cri, sortfield: 'created', direction: 'desc' }
    setSaveCriteria(filtcri);
    setFilterCriteria(filtcri);
    getDataFromServer(filtcri);
    
  }

  // detailed view and daily view screen buttons in timesheet
  const detailedViewScreen = () => {
    setEmpNameFlag(true);
    setDailyView('');
    setPendingApprovals('');
    getTableFieldsOrder();
    getDataFromServer();
  }

  const dailyViewScreen = async () => {
    setEmpNameFlag(false);
    setDailyView('daily')
    setPendingApprovals('')
    let dailytablefields = props.getTableFields();
    const newTableFields = dailytablefields.filter(item => item.field == 'Sno' || item.field == 'date' || item.field == 'timesheetHours' || item.field == 'employeeName');

    let filterCriteria = { limit: 20, page: 1, criteria: [], sortfield: 'date', direction: 'desc' }
    setTablefieldsToShow(newTableFields);
    getDataFromServer(filterCriteria, '', 'daily')
  }

  const PendingApprovalScreen = () => {
    setDailyView('');
    setPendingApprovals('approvals')
    getTableFieldsOrder();
    getDataFromServer('', '', '', 'approvals');
  }

  const getScreenHeader = () => {
    const { t } = props;
    return (
      <div className='pb-4'>
        <div className='row m-3'>
          <div className='col-md-2 d-flex flex-wrap'>
            <h4>
              <span className='postionRelative'>
                <b style={{ fontSize: '20px' }}>
                  <a onClick={() => closeFormModal}>
                    {t(props.displayName)}
                  </a>{isOpenFormModal && displayViewOfForm === 'screen' ? ` / ${formType} ` : null}
                </b>
              </span>
            </h4>
          </div>
          <div className='col-md-4 custom-btn' style={{ whiteSpace: 'nowrap', transform: 'scale(1)' }}>
            {props.type == 'TimeSheets' && <Button color="primary" onClick={dailyViewScreen} style={{ fontSize: '12px' }}>{'Daily View'}</Button>}
            {props.type == 'TimeSheets' && <Button color="primary" onClick={detailedViewScreen} style={{ fontSize: '12px' }}>{'Detailed View'}</Button>}
            {props.type == 'TimeSheets' && (loginRole.roleType === "Admin" || loginRole.roleType === "Manager") ?
              <Button color="primary" onClick={PendingApprovalScreen} style={{ fontSize: '12px' }}>
                {'My Pending Approvals'}
              </Button>
              : null
            }
          </div>
          <div className='col-md-6 d-flex flex-wrap justify-content-end gap-2'>
            <FilterOperations
              saveCriteria={saveCriteria}
              getDataFromServer={getDataFromServer}
              screenName={props.type}
              getCriteriaOfSavedFilt={getCriteriaOfSavedFilt}
            />
          </div>
        </div>

        <div className='row pt-3'>
          <div className={props.type === 'Leaves' ? 'col-md-6 d-flex' : 'col-md-8 d-flex'} style={{ height: '80%' }}>
            {
              props.filterRequired ? <span className='mr-3' ref={closeMultiSelectDropdown}>
                <span className='multiSelectDropDownCard '>
                  <span onClick={toogleMultiSelect}>
                    {t('Filters')}
                  </span>
                  <span onClick={toogleMultiSelect}>
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      className='ml-1'
                      color='grey'
                    />
                  </span>
                  {
                    showMultiSelectDropDown ?
                      <Card className=' itemsWarperCard' >
                        {
                          tablefieldsToShow && tablefieldsToShow.length > 0 ? tablefieldsToShow.map((item, index) => {
                            return (
                              item.displayInFilter && item.show && < div >
                                <p className='multiSelectDropDownCardUl'>{t(item.header)}</p>
                                <ul className='pl-0'>
                                  {
                                    item.options && item.options.length > 0 ? item.options.map((optionsItem, optinsIndex) => {
                                      return (
                                        <div className='multiSelectWrapperItems' onClick={() => changeCheckIcon(index, optinsIndex, item.field, optionsItem.value)}>
                                          <span className='chekcIconAdjust'>
                                            <Checkbox
                                              // onChange={e => setChecked(e.checked)}
                                              checked={optionsItem.checkIcon}>
                                            </Checkbox>
                                            {/* <FontAwesomeIcon
                                                icon='check'
                                                color={optionsItem.checkIcon ? 'grey' : 'white'}
                                              /> */}
                                          </span>
                                          <span className='chekcIconAdjustLabel'>
                                            {t(optionsItem.label)}
                                          </span>
                                        </div>
                                      )
                                    }) : null
                                  }
                                </ul>
                                <hr className='my-0'></hr>
                              </div>
                            )
                          }) : null
                        }
                      </Card>
                      : null}
                </span>

              </span> : null
            }
            {/* {!isOpenFormModal ? <MultiSelect
                  style={{
                    lineHeight: 0,
                    position: 'relative',
                    top: 4,
                    paddingTop: 9,
                    paddingBottom: 9,
                    paddingLeft: 1,
                    paddingRight: 1,
                  }}
                  appendTo={document.body}
                  className='mr-3'
                  name='multiActions'
                  placeholder={'Filters'}
                  maxSelectedLabels={2}
                  selectedItemsLabel='Aldfdefel'
                  value={state['multiActions'] ? state['multiActions'] : null}
                  options={props.actionsTypes}
                  onChange={(e) => onFilterChange(e, 'dropdownFilter')}
                /> : null} */}

            {screenPermissions?.actions && screenPermissions.actions.length > 0 && props.type!='Leaves'? <Dropdown
              style={{ minWidth: '10%', lineHeight: 1, marginTop: '2px' }}
              className='mr-3'
              // appendTo={document.body}
              name='actions'
              value={actions}
              disabled={selectedRows && selectedRows.length > 0 ? false : true}
              options={actionsTypes}
              placeholder={t('Actions')}
              onChange={(e) => onActionsChange(e, 'dropdownFilter')}
            /> : null}
            <div className='ps-2 flex flex-wrap justify-content-center gap-2'>
              {props.printRequried ?

                <Button
                  raised
                  size="small"
                  onClick={() => print()}
                >
                  <FontAwesomeIcon
                    icon='print'
                    className='pl-1' size='lg'
                    data-toggle="tool-tip" title={t("Print")}

                  />
                </Button>
                : null}
              {props.exportRequired &&
                <Button
                  size="small"
                  onClick={getDataToExport}>
                  <FontAwesomeIcon
                    icon='file'
                    data-toggle="tool-tip" title={t("Export To CSV")}
                    className='pl-1' size='lg' />
                </Button>
              }
              {props.exportRequired &&
                <CSVLink
                  data={exportData}
                  filename={`${props.type}.csv`}
                  className="hidden text-white"
                  ref={csvLinkRef}
                  target="_blank" >
                </CSVLink>}
              {props.sample ?
                <Button
                  size="small"
                  onClick={() => bulkUploadMoadal()}>
                  <FontAwesomeIcon
                    icon='upload'
                    size='lg'
                    data-toggle="tool-tip" title={t("Bulk Upload")}
                    onClick={() => bulkUploadMoadal()} />
                </Button>
                : null}

              {props.gridRequried ?
                <div class="btn-group mb-0" role="group">
                  <Button
                    outlined={viewType === 'grid' ? false : true}
                    size="small"
                    style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                    onClick={() => changeViewType('grid')}
                  >
                    <FontAwesomeIcon
                      icon='grip-horizontal'
                      size="small"
                      data-toggle="tool-tip" title={t("Grid")}
                      onClick={() => changeViewType('grid')}
                    />
                  </Button>
                  <Button
                    size="small"
                    outlined={viewType === 'list' ? false : true}
                    style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                    onClick={() => changeViewType('list')}
                  >
                    <FontAwesomeIcon
                      icon='list'
                      size='lg'
                      data-toggle="tool-tip" title={t("List")}
                      onClick={() => changeViewType('list')}
                    />
                  </Button>
                </div> : null}

              {props.settingsRequired ?

                <Button
                  size="small"
                  onClick={() => openShowHideColumnsModal()}
                >
                  <FontAwesomeIcon
                    icon='cog'
                    className='pl-1' size='lg'
                    data-toggle="tool-tip" title={t("Settings")}
                    onClick={() => openShowHideColumnsModal()}
                  />
                </Button> : null}

              {/* Add button */}
              {props.addRequired ?
                // <a href={`/create_${props.routeTo}`}  >
                <Button onClick={() => openFormModal({}, 'add')} >
                  <FontAwesomeIcon
                    icon='plus'
                    size='lg'
                    data-toggle="tool-tip" title={t("Add")}
                  // onClick={() =>   openFormModal('add')}
                  />
                </Button>
                // </a>
                : null}

              {/* priority  in Issues*/}
              {<Button
                color="secondary"
                size="small"
                // className="p-1 ml-auto"

                onClick={() => { getDataFromServer(filterCriteria, 'refresh', dailyView, pendingApprovals); }}>
                <FontAwesomeIcon
                  icon='sync-alt'
                  size='lg'
                  data-toggle="tool-tip" title={t("Refresh")}
                  color={config.templateColor}
                  className='refreshIcon pl-1' />
              </Button>}
              {/*KEEP-MIGHT NEED LATER {props.type === 'Tasks' ?
                <Button color="primary" size="small" onClick={myTaskFilters}>{'My Tasks'}</Button>
                : null} */}
              {props.type === 'Tasks' ?
                <Button color="primary" size="small" onClick={getBacklogTasks} disabled={showBacklog}>{'Backlog'}</Button>
                : null}
              {props.type === 'Employees' && (loginRole.roleType === "Admin" || loginRole.roleType === "HR") ?
                <Button color="primary" className='ml-auto gap-1' onClick={(e) => { e.preventDefault(); props.getMonthlyReport() }}>
                  <FontAwesomeIcon icon='file' />
                  Monthly Report
                </Button>
                : null
              }
            </div>


          </div>

          <div className='col-12  pb-2 mt-4 d-none'>
            <span className='float-right pt-2'>
              <div className='col-12 px-0 pb-1'>
                {config.paginationPosition == 'top' ? getPaginator() : null}
              </div>
            </span>
            <span className='pt-2'>
              {/* Export to csv */}
              <span className='float-left pt-2' >
                {props.filterRequired ? <span className='mr-3' ref={closeMultiSelectDropdown}>
                  <span className='multiSelectDropDownCard '>
                    <span onClick={toogleMultiSelect}>
                      {t('Filters')}
                    </span>
                    <span onClick={toogleMultiSelect}>
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className='ml-1'
                        color='grey'
                      />
                    </span>
                    {
                      showMultiSelectDropDown ?
                        <Card className=' itemsWarperCard' >
                          {
                            tablefieldsToShow && tablefieldsToShow.length > 0 ? tablefieldsToShow.map((item, index) => {
                              return (
                                item.displayInFilter && item.show && < div >
                                  <p className='multiSelectDropDownCardUl'>{t(item.header)}</p>
                                  <ul className='pl-0'>
                                    {
                                      item.options && item.options.length > 0 ? item.options.map((optionsItem, optinsIndex) => {
                                        return (
                                          <div className='multiSelectWrapperItems' onClick={() => changeCheckIcon(index, optinsIndex, item.field, optionsItem.value)}>
                                            <span className='chekcIconAdjust'>
                                              <Checkbox
                                                // onChange={e => setChecked(e.checked)}
                                                checked={optionsItem.checkIcon}>
                                              </Checkbox>
                                              {/* <FontAwesomeIcon
                                                icon='check'
                                                color={optionsItem.checkIcon ? 'grey' : 'white'}
                                              /> */}
                                            </span>
                                            <span className='chekcIconAdjustLabel'>
                                              {t(optionsItem.label)}
                                            </span>
                                          </div>
                                        )
                                      }) : null
                                    }
                                  </ul>
                                  <hr className='my-0'></hr>
                                </div>
                              )
                            }) : null
                          }
                        </Card>
                        : null}
                  </span>

                </span> : null}
                {/* {!isOpenFormModal ? <MultiSelect
                  style={{
                    lineHeight: 0,
                    position: 'relative',
                    top: 4,
                    paddingTop: 9,
                    paddingBottom: 9,
                    paddingLeft: 1,
                    paddingRight: 1,
                  }}
                  appendTo={document.body}
                  className='mr-3'
                  name='multiActions'
                  placeholder={'Filters'}
                  maxSelectedLabels={2}
                  selectedItemsLabel='Aldfdefel'
                  value={state['multiActions'] ? state['multiActions'] : null}
                  options={props.actionsTypes}
                  onChange={(e) => onFilterChange(e, 'dropdownFilter')}
                /> : null} */}

                {!isOpenFormModal && actionsTypes && actionsTypes.length > 0 ? <Dropdown
                  style={{ minWidth: '10%', lineHeight: 1.3, marginTop: '2px' }}
                  className='mr-3'
                  // appendTo={document.body}
                  name='actions'
                  value={actions}
                  disabled={selectedRows && selectedRows.length > 0 ? false : true}
                  options={actionsTypes}
                  placeholder={t('Actions')}
                  onChange={(e) => onActionsChange(e, 'dropdownFilter')}
                /> : null}
                <div className='flex flex-wrap justify-content-center gap-3'>
                  {!isOpenFormModal && props.printRequried ?

                    <Button
                      raised
                      size="sm"

                      onClick={() => print()}
                    >
                      <FontAwesomeIcon
                        icon='print'
                        className='pl-1' size='lg'
                        data-toggle="tool-tip" title={t("Print")}
                        onClick={() => print()}
                      />
                    </Button>

                    : null}
                  {!isOpenFormModal && props.exportRequired && <Button color="primary"
                    className='p-1 ml-auto mb-0 mt-1'
                    size={'sm'} onClick={getDataToExport}>
                    <FontAwesomeIcon
                      icon='file'
                      data-toggle="tool-tip" title={t("Export To CSV")}
                      className='pl-1' size='lg' />
                  </Button>}
                  {!isOpenFormModal && props.exportRequired && <CSVLink
                    data={exportData}
                    filename={`${props.type}.csv`}
                    className="hidden text-white"
                    // ref={(r) => csvLinkRef = r}
                    target="_blank" >
                  </CSVLink>}
                  {!isOpenFormModal && props.sample ?
                    <Button color="primary"
                      size="sm"
                      className="p-1 mt-1 mb-0"
                      onClick={() => bulkUploadMoadal()}>
                      <FontAwesomeIcon
                        icon='upload'
                        className='pl-1' size='lg'
                        data-toggle="tool-tip" title={t("Bulk Upload")}
                        onClick={() => bulkUploadMoadal()} />
                    </Button>
                    : null}

                  {!isOpenFormModal && props.gridRequried ? <ButtonGroup className='mb-0 mr-3'>
                    <Button color="primary"
                      outline={viewType === 'grid' ? false : true}
                      size="sm"
                      className="p-1 ml-auto mt-1 mb-0"
                      onClick={() => changeViewType('grid')}
                    >
                      <FontAwesomeIcon
                        icon='grip-horizontal'
                        className='pl-1' size='lg'
                        data-toggle="tool-tip" title={t("Grid")}
                        onClick={() => changeViewType('grid')}
                      />
                    </Button>
                    <Button color="primary"
                      size="sm"
                      outline={viewType === 'list' ? false : true}
                      className="p-1 ml-auto mt-1 mb-0"
                      onClick={() => changeViewType('list')}
                    >
                      <FontAwesomeIcon
                        icon='list'
                        className='pl-1' size='lg'
                        data-toggle="tool-tip" title={t("List")}
                        onClick={() => changeViewType('list')}
                      />
                    </Button>
                  </ButtonGroup> : null}

                  {!isOpenFormModal && props.settingsRequired ?
                    <span> &nbsp;
                      <Button color="primary"
                        size="sm"
                        className="p-1 ml-auto mt-1 mb-0"
                        onClick={() => openShowHideColumnsModal()}
                      >
                        <FontAwesomeIcon
                          icon='cog'
                          className='pl-1' size='lg'
                          data-toggle="tool-tip" title={t("Settings")}
                          onClick={() => openShowHideColumnsModal()}
                        />
                      </Button> </span> : null}

                  {/* Add button */}
                  {props.addRequired && !isOpenFormModal ?
                    // <a href={`/create_${props.routeTo}`}  >
                    <Button color="primary"
                      size="sm"
                      className="p-1 ml-auto mt-1 mb-0"
                    // onClick={() => openFormModal('add')}
                    >
                      <FontAwesomeIcon
                        icon='plus'
                        className='pl-1' size='lg'
                        data-toggle="tool-tip" title={t("Add")}
                      // onClick={() =>   openFormModal('add')}
                      />
                    </Button>
                    // </a>
                    : null}

                  {/* priority  in Issues*/}
                  {!isOpenFormModal &&
                    <span> &nbsp;
                      <Button
                        color="secondary"
                        size="sm"
                        // className="p-1 ml-auto"
                        className={props.addRequired ? "p-1 mt-1 mb-0" : 'p-1 ml-auto mt-1 mb-0'}
                        onClick={() => getDataFromServer(filterCriteria, 'refresh')}>
                        <FontAwesomeIcon
                          icon='sync-alt'
                          size='lg'
                          data-toggle="tool-tip" title={t("Refresh")}
                          color={config.templateColor}
                          className='refreshIcon pl-1' />
                      </Button> </span>}
                </div>
              </span>
            </span>
          </div>

          <div className={props.type === 'Leaves' ? 'col-md-2 d-flex' : 'd-none'}>
            {(props.type == 'Leaves' && (leaveBalance || leaveBalance === 0)) &&
              <><b style={{ fontSize: "16px", marginTop: "10px" }}> {'Leave Balance'}: {leaveBalance} </b></>
            }
          </div>

          <div className='col-4 d-flex'>
            {/* global Search */}
            <div className={(props.dateSearchRequired) ? "col-9" : 'col-3'}>
              {(props.dateSearchRequired) ?
                <div className='row'>
                  <RenderIntervalDatePickerField handleDateValueInParent={handleSearchDateInterval}>
                  </RenderIntervalDatePickerField>
                  <Button className="col-sm-2 mb-0" color="primary" onClick={searchInDateRanges}>{t('dataTable.table_head.search')}</Button>
                </div>
                : null}
            </div>
            { // !isOpenFormModal && 
              props.globalSearch ?
                <div className={(props.dateSearchRequired) ? "col-3" : 'col-9'}>
                  <div className='serachAlignment float-right' >
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">
                        < i className="pi pi-search" ></i >
                      </span>
                      <InputText
                        type="search"
                        name='globalSearch'
                        id='globalSearch'
                        value={globalSearch}
                        onChange={(e) => onFilterChange(e)}
                        placeholder={'Search'}
                        size="25" />
                    </div>
                  </div>
                </div> : null
            }
          </div>
        </div>

      </div >
    )
  }

  const onGlobalSerachFilterChange = async (event) => {
    if (event) {
      await onFilterChange(event)
    }

  }

  const getHeader = () => {
    const { t } = props;
    return (
      // <div className="row">
      //   {/* global Search */}
      //   <div className='col-6'>
      //   </div>
      //   <div className='col-6'>
      //     <div className='serachAlignment float-right' >
      //       <div className="p-inputgroup">
      //         <span className="p-inputgroup-addon">
      //           <i className="pi pi-search" ></i>
      //         </span>
      //         <InputText
      //           type="search"
      //           name='globalSearch'
      //           onChange={(e) => onFilterChange(e)}
      //           placeholder={props.globalSearch ? props.globalSearch : 'search'}
      //           size="30" />
      //       </div>
      //     </div>
      //   </div>
      // </div >

      <div>
        <h4><span className='postionRelative pt-2'>
          <b
          ><a onClick={() => closeFormModal} >
              {t(props.type)}
            </a>{isOpenFormModal && displayViewOfForm === 'screen' ? ` / ${formType} ` : null}
          </b>
        </span>
        </h4>
        <div className="row">
          {/* global Search */}
          <div className={(props.dateSearchRequired) ? "col-9" : 'col-6'}>
            {(props.dateSearchRequired) ?
              <div className='row'>
                <RenderIntervalDatePickerField handleDateValueInParent={handleSearchDateInterval}>
                </RenderIntervalDatePickerField>
                <Button className="col-sm-2 mb-0" color="primary" onClick={searchInDateRanges}>{t('dataTable.table_head.search')}</Button>
              </div>
              : null}
          </div>
          {/**@modalFix */}
          {/* {
          props.globalSearch ?
            <div className={(props.dateSearchRequired) ? "col-3" : 'col-6'}>
              <div className='serachAlignment float-right' >
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    < i className="pi pi-search" ></i >
                  </span>
                  <InputText
                    type="search"
                    name='globalSearch'
                    id='globalSearch'
                    value={state['globalSearch']}
                    onChange={(e) => onFilterChange(e)}
                    placeholder={props.globalSearch ? (props.globalSearch) : 'search'}
                    size="25" />
                </div>
              </div>
            </div> : null
          } */}
        </div >
        <div className='row'>
          {/* global Search */}
          <div className='col-sm-12'>
            {/* <h4 >
              <span className='postionRelative pt-2'>
                <b
                ><Link to={(props.routeTo)} onClick={closeFormModal}>
                    {t(props.type)}
                  </Link>{isOpenFormModal && displayViewOfForm === 'screen' ? ` / ${formType} ` : null}
                </b>
              </span>
              {props.dateSearchRequired ? < span >
                <div >
                  <RenderIntervalDatePickerField handleDateValueInParent={handleSearchDateInterval}>
                  </RenderIntervalDatePickerField>
                  <Button className="col-sm-2 mb-0" color="primary" onClick={searchInDateRanges}>Search</Button>
                </div>
              </span> : null}
              <span className='float-right pl-3 '>
                {props.globalSearch && <div>
                  <div
                    className="p-inputgroup"
                  >
                    <span className="p-inputgroup-addon">
                      <i className="pi pi-search" ></i>
                    </span>
                    <InputText
                      type="search"
                      name='globalSearch'
                      id='globalSearch'
                      value={state['globalSearch']}
                      onChange={(e) => onFilterChange(e)}
                      placeholder={props.globalSearch ? (props.globalSearch) : 'search'}
                      size="30" />

                  

                  </div>
                </div>}
              </span>
            </h4> */}
          </div>
          <div className='col-12  pb-1'>
            <span className='float-right pt-2'>
              <div className='col-12 px-0 pb-1'>
                {config.paginationPosition == 'top' ? getPaginator() : null}
              </div>
            </span>
            <span className='pt-2'>
              {/* Export to csv */}
              <span className='float-left pt-2' >
                {props.filterRequired ?
                  <span className='mr-3' ref={closeMultiSelectDropdown}>
                    <span className='multiSelectDropDownCard '>
                      <span onClick={toogleMultiSelect}>
                        {t('Filters')}
                      </span>
                      <span onClick={toogleMultiSelect}>
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          className='ml-1'
                          color='grey'
                        />
                      </span>
                      {
                        showMultiSelectDropDown ?
                          <Card className=' itemsWarperCard' >
                            {
                              tablefieldsToShow && tablefieldsToShow.length > 0 ? tablefieldsToShow.map((item, index) => {
                                return (
                                  item.displayInFilter && item.show && < div >
                                    <p className='multiSelectDropDownCardUl'>{t(item.header)}</p>
                                    <ul className='pl-0'>
                                      {
                                        item.options && item.options.length > 0 ? item.options.map((optionsItem, optinsIndex) => {
                                          return (
                                            <div className='multiSelectWrapperItems' onClick={() => changeCheckIcon(index, optinsIndex, item.field, optionsItem.value)}>
                                              <span className='chekcIconAdjust'>
                                                <Checkbox
                                                  // onChange={e => setChecked(e.checked)}
                                                  checked={optionsItem.checkIcon}>
                                                </Checkbox>
                                                {/* <FontAwesomeIcon
                                           icon='check'
                                           color={optionsItem.checkIcon ? 'grey' : 'white'}
                                         /> */}
                                              </span>
                                              <span className='chekcIconAdjustLabel'>
                                                {t(optionsItem.label)}
                                              </span>
                                            </div>
                                          )
                                        }) : null
                                      }
                                    </ul>
                                    <hr className='my-0'></hr>
                                  </div>
                                )
                              }) : null
                            }
                          </Card>
                          : null}
                    </span>

                  </span>
                  : null}


                {/* {!isOpenFormModal ? <MultiSelect
                  style={{
                    lineHeight: 0,
                    position: 'relative',
                    top: 4,
                    paddingTop: 9,
                    paddingBottom: 9,
                    paddingLeft: 1,
                    paddingRight: 1,
                  }}
                  appendTo={document.body}
                  className='mr-3'
                  name='multiActions'
                  placeholder={'Filters'}
                  maxSelectedLabels={2}
                  selectedItemsLabel='Aldfdefel'
                  value={state['multiActions'] ? state['multiActions'] : null}
                  options={props.actionsTypes}
                  onChange={(e) => onFilterChange(e, 'dropdownFilter')}
                /> : null} */}

                <Dropdown
                  style={{ minWidth: '10%', lineHeight: 1.3, marginTop: '2px' }}
                  className='mr-3'
                  // appendTo={document.body}
                  name='actions'
                  value={actions}
                  disabled={selectedRows && selectedRows.length > 0 ? false : true}
                  options={actionsTypes}
                  placeholder={t('Actions')}
                  onChange={(e) => onActionsChange(e, 'dropdownFilter')}
                />

                {props.printRequried ?
                  <Button color="primary"
                    size="sm"
                    className="p-1 ml-auto  mb-0 mt-1"
                    onClick={() => print()}
                  >
                    <FontAwesomeIcon
                      icon='print'
                      className='pl-1' size='lg'
                      data-toggle="tool-tip" title={t("Print")}
                      onClick={() => print()}
                    />
                  </Button>
                  : null}
                {props.exportRequired && <Button color="primary"
                  className='p-1 ml-auto mb-0 mt-1'
                  size={'sm'} onClick={getDataToExport}>
                  <FontAwesomeIcon
                    icon='file'
                    data-toggle="tool-tip" title={t("Export To CSV")}
                    className='pl-1' size='lg' />
                </Button>}
                {/**@ModalFix */}
                {/* {props.exportRequired && <CSVLink
                  data={exportData}
                  filename={`${props.type}.csv`}
                  className="hidden text-white"
                  ref={(r) => csvLinkRef = r}
                  target="_blank" >
                </CSVLink>} */}
                {props.sample ?
                  <Button color="primary"
                    size="sm"
                    className="p-1 mt-1 mb-0"
                    onClick={() => bulkUploadMoadal()}>
                    <FontAwesomeIcon
                      icon='upload'
                      className='pl-1' size='lg'
                      data-toggle="tool-tip" title={t("Bulk Upload")}
                      onClick={() => bulkUploadMoadal()} />
                  </Button>
                  : null}

                {props.gridRequried && <ButtonGroup className='mb-0 mr-3'>
                  <Button color="primary"
                    outline={viewType === 'grid' ? false : true}
                    size="sm"
                    className="p-1 ml-auto mt-1 mb-0"
                    onClick={() => changeViewType('grid')}
                  >
                    <FontAwesomeIcon
                      icon='grip-horizontal'
                      className='pl-1' size='lg'
                      data-toggle="tool-tip" title={t("Grid")}
                      onClick={() => changeViewType('grid')}
                    />
                  </Button>
                  <Button color="primary"
                    size="sm"
                    outline={viewType === 'list' ? false : true}
                    className="p-1 ml-auto mt-1 mb-0"
                    onClick={() => changeViewType('list')}
                  >
                    <FontAwesomeIcon
                      icon='list'
                      className='pl-1' size='lg'
                      data-toggle="tool-tip" title={t("List")}
                      onClick={() => changeViewType('list')}
                    />
                  </Button>
                </ButtonGroup>}
                {props.settingsRequired && <Button color="primary"
                  size="sm"
                  className="p-1 ml-auto mt-1 mb-0"
                  onClick={() => openShowHideColumnsModal()}
                >
                  <FontAwesomeIcon
                    icon='cog'
                    className='pl-1' size='lg'
                    data-toggle="tool-tip" title={t("Settings")}
                    onClick={() => openShowHideColumnsModal()}
                  />
                </Button>}

                {/* Add button */}
                {props.addRequired ?
                  // <a href={`/create_${props.routeTo}`} >
                  <Button color="primary"
                    size="sm"
                    className="p-1 ml-auto mt-1 mb-0"
                  // onClick={() => openFormModal('add')}
                  >
                    <FontAwesomeIcon
                      icon='plus'
                      className='pl-1' size='lg'
                      data-toggle="tool-tip" title={t("Add")}
                    // onClick={() => openFormModal('add')}
                    />
                  </Button>
                  // </a>
                  : null}

                {/* priority  in Issues*/}
                <Button
                  color="secondary"
                  size="sm"
                  // className="p-1 ml-auto"
                  className={props.addRequired ? "p-1 mt-1 mb-0" : 'p-1 ml-auto mt-1 mb-0'}
                  onClick={() => getDataFromServer(filterCriteria, 'refresh')}>
                  <FontAwesomeIcon
                    icon='sync-alt'
                    size='lg'
                    data-toggle="tool-tip" title={t("Refresh")}
                    color={config.templateColor}
                    className='refreshIcon pl-1' />
                </Button>

              </span>
            </span>
          </div>
        </div>

      </div >
    )
  }

  const getCardHeader = () => {
    return (
      // <CardHeader className='cardHeader'>
      <div className="row m-0">
        {/* table name */}
        <div className='col-3 pl-0 pt-2'>
          <h4><b>{props.type}{props.type == 'Activiti' ? 'es' : ''}</b></h4>
        </div>
        <span className='col-9 text-right'>
          <Row>
            {/* Export to csv */}
            {/* <Button color="primary"
                    className='p-1 ml-auto'
                    size={'sm'} onClick={getDataToExport}>
                    <FontAwesomeIcon
                      icon='file'
                      data-toggle="tool-tip" title="Export To CSV"
                      className='pl-1' size='lg' />
                  </Button>
                  <CSVLink
                    data={exportData}
                    filename={`${props.type}.csv`}
                    className="hidden text-white"
                    ref={(r) => csvLinkRef = r}
                    target="_blank" >
                  </CSVLink> */}
            {/* {props.sample ?
              <Button color="primary"
                size="sm"
                className="p-1"
                onClick={bulkUploadMoadal}>
                <FontAwesomeIcon
                  icon='upload'
                  className='pl-1' size='lg'
                  data-toggle="tool-tip" title="Bulk Upload"
                  onClick={bulkUploadMoadal} />
              </Button>
              : null} */}
            {/* Add Settings Button */}
            {props.settingsRequired ?
              <Button
                raised
                size="sm"
                className="p-1 ml-auto mt-1"
                onClick={() => openShowHideColumnsModal()}
              >
                <FontAwesomeIcon
                  icon='cog'
                  className='pl-1' size='lg'
                  data-toggle="tool-tip" title="Settings"
                  onClick={() => openShowHideColumnsModal()}
                />
              </Button>
              : null}
            {/* Add button */}
            {props.addRequired ?
              <a href={`/create_${props.routeTo}`} >
                <Button color="primary"
                  size="sm"
                  className="p-1 ml-auto"
                // onClick={() => openFormModal('add')}
                >
                  <FontAwesomeIcon
                    icon='plus'
                    className='pl-1' size='lg'
                    data-toggle="tool-tip" title="Add"
                  //  onClick={() => openFormModal('add')}
                  />
                </Button></a>
              : null}
            {/* priority  in Issues*/}
            <Button
              color="secondary"
              size="sm"
              // className="p-1 ml-auto"
              className={props.addRequired ? "p-1" : 'p-1 ml-auto'}
              onClick={() => getDataFromServer(filterCriteria, 'refresh')}>
              <FontAwesomeIcon
                icon='sync-alt'
                size='lg'
                data-toggle="tool-tip" title="Refresh"
                color={config.templateColor}
                className='refreshIcon pl-1' />
            </Button>
          </Row>
        </span>

      </div>
      // </CardHeader>
    )
  }

  const cancelUserPwdResetModal = async () => {
    setOpenUserPasswordResetModal(false);
    setActions('');
  }

  const handleSearchDateInterval = (startDate, endDate) => {
    if (startDate) {
      setStartDate(startDate);
    }
    if (endDate) {
      setEndDate(endDate);
    }
  }

  const searchInDateRanges = () => {
    if (startDate && endDate) {
      let filterCriteria = {};
      let startDate = (moment(startDate).format('YYYY-MM-DD'));
      let endDate = (moment(endDate).format('YYYY-MM-DD'));
      filterCriteria['limit'] = 20;
      filterCriteria['page'] = 1;
      filterCriteria['criteria'] = [
        { 'key': searchInDateRangeField, 'value': startDate, 'type': 'gte' },
        { 'key': searchInDateRangeField, 'value': endDate, 'type': 'lte' }];
      getDataFromServer(filterCriteria);
    }
    else {
      alert('no dates selected');
    }
  }

  const getColumns = (e, d) => {

    const { t } = props
    // const self = this;
    // self.e = e;
    // self.d = d;
    if (tablefieldsToShow && tablefieldsToShow.length > 0) {
      return tablefieldsToShow.map((item, i) => {
        if (item.type == "relateAutoComplete") item.filter = true;
        return (
          <Column key={item.field + i}
            style={{
              maxwidth: item.width,

            }}
            bodyStyle={item.capitalizeTableText ? {
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textTransform: 'capitalize',
              textAlign: item.type == 'date' || item.type == 'dropDown' ? 'center' : 'left'
            } : {
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textAlign: item.type == 'date' || item.type == 'dropDown' || item.field == 'role' || item.type == 'number' || item.fieldType == 'Actions' || item.type == 'checkBox' ? 'center' : 'left'
            }}
            field={item.field}
            editRequired={e}
            deleteRequired={d}
            header={t(item.header)}
            changeFieldName={item.changeFieldName}
            body={changeFieldValues}
            headerStyle={{
              padding: 4, fontWeight: 500, width: item.width, fontSize: 13,
              // color: config.whiteColor, backgroundColor: config.templateColor
            }}
            filter={item.filter ? item.filter : false}
            dataType={item.type == "date" ? "date" : item.type == "number" ? "numeric" : "text"}
            showFilterMatchModes={item.type == "dropDown" || item.type == "radio" ? false : true}
            showClearButton={false}
            showApplyButton={(item.type == "dropDown" || item.type == 'date' || item.type == 'radio') ? false : true}

            // showFilterMenu={false}
            sortable={item.sortable ? true : false}
            // filterPlaceholder={item.placeholder ? item.placeholder : 'search'}            
            filterElement={item.filterElement ?
              multiSelectFilterTemplate


              : item["type"] == "date" ?
                dateFilterTemplate

                : null
            }
            selectionMode={item.selectionMode}
          />)
      })
    }

  }

  const multiSelectFilterTemplate = (options) => {
    let filterOptions = [];
    filterOptions = getMultiSelectOptions(options);

    return <MultiSelect
      value={options.value}
      options={filterOptions}
      maxSelectedLabels={1}
      optionalLabel="label"
      onChange={(e) => options.filterApplyCallback(e.value, options.index)}
      placeholder="Any"
      className="p-column-filter" />;
  };

  const getMultiSelectOptions = (options) => {
    let tableFields = props.getTableFields();
    let i = tableFields.findIndex((col) => col.field === options.field);
    if (i > -1 && tableFields[i].filterElement) {
      return tableFields[i].filterElement;
    } else {
      return [];
    }
  }

  const dateFilterTemplate = (options) => {
    return <Calendar value={options.value} showButtonBar selectionMode="range" onChange={(e) => options.filterApplyCallback(e.value, options.index)} />;
  };

  const getDataTable = () => {


    return (
      <DataTable
        rowClassName={rowClassName}
        ref={(el) => dt = el}
        value={allUsersData}
        // header={getHeader()}
        totalRecords={totalRecordsLength}
        paginator={false}
        lazy={true}
        resizableColumns={true}
        columnResizeMode="expand"
        onSort={sortChange}
        // globalFilter={globalFilter}
        onFilter={onFilterColumns}
        scrollable={true}
        // selection={false}
        onSelectionChange={e => onSelectRowsUpdate(e)}
        scrollHeight='610px'
        // style={{ marginTop: 0 }}
        emptyMessage={configMessages.noRecords}
        // sortMode="single"
        // sortField="fname"
        // sortOrder={-1
        // showGridlines={true}
        selection={selectedRows}
        selectionMode={'multiple'}
        metaKeySelection={false}
        loading={isLoading}
        style={allUsersData && allUsersData.length == 0 ?
          { textAlign: 'center' }
          : null}
      // filterDisplay="row"
      >
        {getColumns(props.editRequried, props.deleteRequried)}
      </DataTable>
    )
  }

  const getTabInfo = () => {
    //console.log('from datatable  getTabinfo');
    return null;
  }

  const getPaginator = () => {
    return (
      <PaginatorComponent
        totalRecords={totalRecordsLength}
        first={first}
        rows={rows}
        onPageChange={onPageChange}
        isWeb={true}
      />
    )
  }

  //getGridView
  const getGridView = () => {
    return (
      <div className='row   ml-lg-0 pr-1'>
        {
          allUsersData && allUsersData.length > 0 ?
            allUsersData.map((item, i) => {
              return (
                <div className='col-sm-6 col-md-4 col-lg-3 px-1' key={i}>
                  <Card className='pb-2' >
                    <CardBody className='tableCardBody'>
                      {
                        tablefieldsToShow && tablefieldsToShow.length && tablefieldsToShow.length > 0 ?
                          tablefieldsToShow.map((elememt, index) => {
                            return (
                              <div className={(elememt.displayInSettings === true) ? `col-12  ` : 'd-none'} key={index}>
                                <div >
                                  {/* {changeFieldValues(item, element)} */}
                                  {
                                    elememt.fieldType === 'Link' ?
                                      <div className='d-flex'>
                                        <span
                                          style={elememt.style}
                                          onClick={() => openViewModal(item, 'view')}>
                                          <b> {item[elememt.field]}</b>
                                        </span>
                                      </div>
                                      :
                                      elememt.fieldType === 'Badge' ?
                                        <div style={elememt.style}>
                                          <CustomBadge  val={item[elememt.field]}  color={item.status == 'Active' ? 'success' : item.status == 'Inactive' ? 'warning' : item.status == 'Pending' ? 'danger' : item.status == 'Reject' ? 'error' : item.status == 'Completed' ? 'primary' : 'info'} />
                                        </div> :
                                        elememt.fieldType === 'Role' ?
                                          <div style={elememt.style}>
                                            
                                            <CustomBadge  val={item[elememt.field]} color={'success'}/>
                                          </div>
                                          : elememt.fieldType === 'Date' ?
                                            <div>
                                              {dateFormats.formatDate(item[elememt.field], config.dateDayMonthFormat)}
                                            </div>
                                            : elememt.fieldType === 'Time' ?
                                              <div>
                                                {dateFormats.formatDate(item[elememt.field], config.timeFormat)} - ${dateFormats.formatDate(item['toTime'], config.timeFormat)}
                                              </div>
                                              : elememt.fieldType === 'Array' ?
                                                <span style={element.style}>
                                                  {flattenArray(item[elememt.field])}
                                                </span>
                                                : elememt.fieldType === "dropDown" ?
                                                  <div style={elememt.style}>
                                                    {getBadgeData(elememt, item[elememt.field])}
                                                  </div> : elememt.fieldType === "relateAutoComplete" ?
                                                    < span style={elememt.style}>
                                                      {item[elememt.field] && elememt.searchField && item[elememt.field][elememt.searchField] ? item[elememt.field][elememt.searchField] : null}
                                                    </span>
                                                    : <div style={elememt.style}>
                                                      <span
                                                      >{item[elememt.field]}</span></div>
                                  }
                                </div>
                              </div>
                            )
                          }) : null
                      }
                    </CardBody>
                  </Card>
                </div>

              )
            }) : null
        }
      </div>
    )
  }

  const getBadgeData = (element, value) => {
    let mcolor = getColorFromOptions(element.options, value);
    return(<CustomBadge  val={value} color={mcolor}/>)
  }

  const getTotalCard = () => {
    // console.log("formtype", formType)
    if (viewType === 'list' && displayViewOfForm != "sideForm") {
      return (
        <Card className='cardForListMargin' >
          <CardBody className='tableCardBody'>
            {!isOpenFormModal && displayViewOfForm === 'screen' ? getScreenHeader() : displayViewOfForm === 'modal' ? getScreenHeader() : null}
            {getTabInfo()}
            {!isOpenFormModal && displayViewOfForm === 'screen'
              ? getDataTable() : displayViewOfForm === 'modal' ? getDataTable() : null}
            {isOpenFormModal ? getFormModal() : null}
            {config.paginationPosition == 'bottom' ? getPaginator() : null}
          </CardBody>
        </Card>
      )
    }
    if (viewType === 'list') {
      if (displayViewOfForm == "sideForm" && props.sideFormLeftOrRight == "right") {
        return (
          <Card className='cardForListMargin' >
            <CardBody className='tableCardBody'>
              {!isOpenFormModal ? getScreenHeader() : null}
              {getTabInfo()}
              {!isOpenFormModal ? getDataTable() : null}
              {isOpenFormModal ? (
                <div className='row'>
                  <div className={props.sideFormLeftOrRight == "right" && props.colWidth === 'narrow' ? 'col-9' : 'col-6'}>
                    {getScreenHeader()}
                    {getTabInfo()}
                    {getDataTable()}
                    {/* {getFormModal()} */}
                    {/* {config.paginationPosition == 'bottom' ? getPaginator() : null} */}
                  </div>
                  <div className={props.sideFormLeftOrRight == "right" && props.colWidth === 'narrow' ? 'col-3' : 'col-6'}
                    style={{ borderLeftStyle: 'solid', borderLeftColor: 'lightgrey' }}>
                    {getFormModal()}
                  </div>
                </div>)
                : null
              }
              {config.paginationPosition == 'bottom' ? getPaginator() : null}
            </CardBody>
          </Card>
        )
      }
      if (displayViewOfForm == "sideForm" && props.sideFormLeftOrRight == "left") {
        return (
          <Card className='cardForListMargin' >
            <CardBody className='tableCardBody'>
              {!isOpenFormModal ? getScreenHeader() : null}
              {getTabInfo()}
              {!isOpenFormModal ? getDataTable() : null}

              {isOpenFormModal ? (
                <div className='row'>
                  <div className='col-3' style={{ borderRightStyle: 'solid', borderRightColor: 'lightgrey' }}>
                    {getFormModal()}
                  </div>
                  <div className='col-9'>
                    {getScreenHeader()}
                    {getTabInfo()}
                    {getDataTable()}
                    {/* {getFormModal()} */}
                    {config.paginationPosition == 'bottom' ? getPaginator() : null}
                  </div >
                </div >)
                : null
              }
              {/* {config.paginationPosition == 'bottom' ? getPaginator() : null} */}
            </CardBody >
          </Card >
        )
      }
    }
    if (viewType === 'grid') {
      if (displayViewOfForm == "sideForm") {
        return (
          <div>
            <Card className={' pb-2 cardForGridMargin'} >
              <CardBody className='tableCardBody'>
                {!isOpenFormModal ? getScreenHeader() : null}
                {getTabInfo()}
              </CardBody>
            </Card>
            {!isOpenFormModal ? getGridView() : null}
          </div>
        )
      }
    }
    if (viewType === 'grid' && displayViewOfForm != "sideForm") {
      return (
        <div>
          <Card
            className={' pb-2 cardForGridMargin'}>
            <CardBody className='tableCardBody pb-0'>
              {!isOpenFormModal && displayViewOfForm === 'screen' ? getScreenHeader() : displayViewOfForm === 'modal' ? getScreenHeader() : null}
              {getTabInfo()}
              {isOpenFormModal ? getFormModal() : null}
            </CardBody>
          </Card>
          {!isOpenFormModal && displayViewOfForm === 'screen' ? getGridView() : displayViewOfForm === 'modal' ? getGridView() : null}
        </div>
      )
    }
  }

  const getMobileCard = () => {
    return (
      <div>
        <Loader loader={isLoading} />
        {isOpenFormModal ? null : <div className='row mr-0 ml-0'>
          <div className='col-sm-12 px-0 pb-2'>
            <h4><span className='mobileScreenHaederView'><b >{props.type}</b></span>
              <span className='float-right'>
                <Button color="primary"
                  size="sm"
                  className="p-1 ml-auto mt-1 mb-0"
                  onClick={() => openShowHideColumnsModal()}
                >
                  <FontAwesomeIcon
                    icon='cog'
                    className='pl-1' size='lg'
                    data-toggle="tool-tip" title="Settings"
                    onClick={() => openShowHideColumnsModal()}
                  />
                </Button>
              </span>
            </h4>
          </div>
        </div>}
        {isOpenFormModal ? getMobileForm() : getGridView()}
        {!isOpenFormModal ? <PaginatorComponent
          totalRecords={totalRecordsLength}
          first={first}
          rows={rows}
          onPageChange={onPageChange}
          isWeb={false}
        /> : null}
      </div >
    )
  }

  const flattenArray = (arrayVal) => {
    let val = '';
    if (arrayVal) {
      val = JSON.stringify(arrayVal);
      val = val.replace(/"/g, '')
        .replace(/\[/g, '')
        .replace(/]/g, '')
        .replace(/{/g, '')
        .replace(/}/g, '')
        .replace(/,/g, ' , ')
        .replace(/:/g, ' : ');
    }
    return val;
  }

  const getMobileForm = () => {
    return (
      <div className='row'>
        <div className='col-sm-12 px-1'>
          <Card className='pb-0'>
            <CardBody className='tableCardBody pb-0'>
              {/* {getScreenHeader()} */}
              {getFormModal()}
            </CardBody>
          </Card>
        </div>
      </div>
    )
  }

  const getFormModal = () => {

    return (
      <FormModal
        onRef={(ref) => formModalRef = ref}
        openFormModal={isOpenFormModal}
        tableRowData={tableRowData}
        allUsersData={allUsersData}
        totalRecords={totalRecordsLength}
        first={first}
        rows={rows}
        page={page}
        closeFormModal={closeFormModal}
        // closeRejectModal={closeRejectModal}
        type={props.type}
        tablefieldsToShow={tablefieldsToShow}
        originalTableFields={originalTableFields}
        formType={formType}
        formFields={props.formFields}
        getDataFromServer={getDataFromServer}
        editRequired={props.editRequired}
        getEditInfoKeys={props.getEditInfoKeys}
        filterExtension={props.filterExtension}
        idNotRequired={props.idNotRequired}
        apiUrl={props.formUrl ? props.formUrl : apiUrl}
        tabType={tabType}
        getDoctorPostingFields={props.getDoctorPostingFields}
        categoryNames={props.categoryNames}
        companiesList={props.companiesList}
        getSettings={getSettings}
        filterCriteria={filterCriteria}
        menuList={props.menuList}
        routeTo={props.routeTo}
        displayViewOfForm={displayViewOfForm}
        sideFormLeftOrRight={props.sideFormLeftOrRight}
        colWidth={props.colWidth}
        displayBreadCrumbValue={displayBreadCrumbValue}
        displayBreadCrumbField={displayBreadCrumbField}
        userStatus={userStatus}
        addSelectedRecord={props.addSelectedRecord}
        actionsTypes={actionsTypes}
        entityType={props.entityType}
        setFilterCriteriaForActivities={setFilterCriteriaForActivities}
        getColorFromOptions={getColorFromOptions}
        selectedId={selectedId}
        getSprintFields={props.getSprintFields}
        dailyView={dailyView}
        isApproval={pendingApprovals}
        isUpdateEditorValues={isUpdateEditorValues}
        screenName={props.type}
        getReleaseItems={props.getReleaseItems}
        savedFiltCriteria={savedFiltCriteria}
        setShowReplyMailDialog={setShowReplyMailDialog}
        showReplyMailDialog={showReplyMailDialog}
      />
    )
  }

  const getViewModal = () => {
    return (
      <ViewModal
        type={props.type}
        openViewModal={openViewModal}
        displayViewOfForm={displayViewOfForm}
        rowData={rowData}
        formFields={props.formFields}
        onRef={(ref) => (viewModalRef = ref)}
        closeViewModal={closeViewModal}
        locationId={locationId}
        drawers={drawers}
      />
    )
  }

  const getOpenShowHideColumnsModal = () => {
    return (
      <ShowHideColumnsModal
        viewType={viewType}
        isOpenShowHideColumnsModal={isOpenShowHideColumnsModal}
        closeShowHideColumnsModal={closeShowHideColumnsModal}
        tableFields={originalTableFields}
        type={props.type}
      />
    )
  }

  const getDeleteRowModal = () => {
    return (
      <DeleteRowModal
        openDeleteModal={openDeleteModal}
        closeDeleteModal={closeDeleteModal}
        selectActions={selectActions}
        deleteSelectedRow={deleteSelectedRow}
        confirmModalText={confirmModalText}
      />
    )
  }

  const getPreviewModal = () => {
    return (
      <PreviewModal
        isPreviewModal={isPreviewModal}
        closePreviewModal={closePreviewModal}
        emailTemplateData={emailTemplateData}
        type={props.type}
      />
    )
  }

  const getSendEMailFields = () => {
    return ([{
      required: true,
      value: '',
      type: 'text',
      name: 'email',
      label: 'To',
      id: 'email',
      placeholder: 'Email'
    },
    {
      required: true,
      value: '',
      type: 'text',
      name: 'subject',
      label: 'Subject',
      id: 'subject',
      placeholder: 'Subject'
    },
    // {
    //   required: true,
    //   value: '',
    //   type: 'radio',
    //   name: 'type',
    //   label: 'Type',
    //   id: 'type',
    //   options:[{label:"Custom",value:"Custom"},{label:"Templates",value:"Templates"}],
    //   placeholder: 'Type'
    // },
    {
      required: true,
      value: '',
      type: 'ckeditor',
      name: 'templateText',
      label: 'Body',
      id: 'emailTemplate',
      placeholder: 'name'
    }])
  }

  const getSendEmailModal = () => {
    return (
      <SendEmailModal
        onRef={(ref) => SendEmailModalRef = ref}
        openSendEmailModal={openSendEmailModal}
        closeSendEmailModal={closeSendEmailModal}
        type={props.type}
        item={selectedRows}
        formFields={getSendEMailFields}
        parentFormFields={props.formFields()}
        apiUrl={props.routeTo}
      />
    )
  }

  const getBulkUploadModal = () => {
    return (
      <BulkUploadModal
        // onRef={(ref) => bulkUploadMoadalRef = ref}
        bulkApi={props.bulkApi}
        type={props.type}
        sample={props.sample}
        sampleFilePath={`${bulkSampleFiles[props.apiUrl]}`}
        // sampleFilePath={props.sampleFilePath}
        openBulkUploadModal={openBulkUploadModal}
        closeBulkModal={closeBulkModal}
        reload={getDataFromServer}
      />
    )
  }

  const getConfirmatioinModal = () => {
    return (
      <ConfirmationModal
        formType={formType}
        onRef={(ref) => (confirmRef = ref)}
        openConfirmationModal={openConfirmationModal}
        closeConfirmationModal={closeConfirmationModal}
        confirm={clickConfirm}
        text={confirmText}
      />
    )
  }

  const getSessionExpiryModal = () => {
    return (
      <SessionExpiryModal
        SOpen={sessionExpiryModal}
      />
    )
  }


  return (
    <div >
      {width <= 576 && getMobileCard()}
      {width >= 577 && getTotalCard()}
      {isOpenShowHideColumnsModal ? getOpenShowHideColumnsModal() : null}
      {openDeleteModal ? getDeleteRowModal() : null}
      {openViewModal ? getViewModal() : null}
      {props.sample ? getBulkUploadModal() : null}
      {isOpenConfirmationModal ? getConfirmatioinModal() : null}
      {sessionExpiryModal ? getSessionExpiryModal() : null}
      {isPreviewModal ? getPreviewModal() : null}
      {redirectToRoute ? <Navigate to={`/${props.routeTo}`} /> : null}
      {openSendEmailModal ? getSendEmailModal() : null}
    </div >
  );

}

const mapStateToProps = state => {
  return { articles: state.articles };
};
const List = connect(mapStateToProps)(DataTables);

export default withTranslation('common')(DataTables);
