import React, { useEffect, useState } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
// config file

const Inventories = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);

  const params = useParams();

  useEffect(() => {
    let screenPermissions = RolePermissions.screenPermissions("Inventories");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
  }, []);

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };

  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };

  const getTableFields = () => {

    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "S.No",
        filter: false,
        sortable: false,
        placeholder: "Search",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
        fieldName: "createdByName",
        tDisplay: true,
      },
      {
        name: "updatedByName",
        type: "Text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        filter: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
        fieldName: "updatedByName",
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "DD-MM-YYYY",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "created",
        fieldName: "created",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "DD-MM-YYYY",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "updated",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "type",
        type: "dropDown",
        placeholder: "Type",
        label: "Type",
        width: "130px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "Type",
        derivedValue: "type=Type=Type=Type=Type=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "type",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: true,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: "Laptop", value: "Laptop", color: "secondary" },
          { label: "Ipad", value: "Ipad", color: "warning" },
          { label: "MacBook", value: "MacBook", color: "danger" },
          { label: "Furniture", value: "Furniture", color: "dark" },
          { label: "Desktop", value: "Desktop", color: "info" },
          { label: "Projector", value: "Projector", color: "success" },
          { label: "Others", value: "Others", color: "primary" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Laptop", value: "Laptop" },
          { label: "Ipad", value: "Ipad" },
          { label: "MacBook", value: "MacBook" },
          { label: "Furniture", value: "Furniture" },
          { label: "Desktop", value: "Desktop" },
          { label: "Projector", value: "Projector" },
          { label: "Others", value: "Others" },
        ],
        defaultValues: ['Laptop', 'Ipad', 'MacBook', 'Furniture', 'Desktop', 'Projector', 'Others'],
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "type",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "operatingSystem",
        type: "dropDown",
        placeholder: "OperatingSystem",
        label: "OperatingSystem",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Operating System",
        derivedValue:
          "operatingSystem=OperatingSystem=OperatingSystem=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "operatingSystem",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: "Windows", value: "Windows", color: "success" },
          { label: "Ubuntu", value: "Ubuntu", color: "info" },
          { label: "MacOS", value: "MacOS", color: "danger" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Windows", value: "Windows" },
          { label: "Ubuntu", value: "Ubuntu" },
          { label: "MacOS", value: "MacOS" },
        ],
        defaultValues: ['Windows', 'Ubuntu', 'MacOS'],
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "operatingSystem",
        showOrHideFields: [],
        fieldName: "operatingSystem",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "brand",
        type: "dropDown",
        placeholder: "Brand",
        label: "Brand",
        width: "130px",
        addFormOrder: 9,
        editFormOrder: 9,
        header: "Brand",
        derivedValue: "brand=Brand=Brand=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "brand",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: "Lenovo", value: "Lenovo", color: "secondary" },
          { label: "Dell", value: "Dell", color: "success" },
          { label: "HP", value: "HP", color: "danger" },
          { label: "Samsung", value: "Samsung", color: "warning" },
          { label: "Microsoft", value: "Microsoft", color: "info" },
          { label: "Apple", value: "Apple", color: "danger" },
          { label: "MI", value: "MI", color: "dark" },
          { label: "Acer", value: "Acer", color: "secondary" },
          { label: "ASUS", value: "ASUS", color: "success" },
          { label: "MSI", value: "MSI", color: "warning" },
          { label: "Sony", value: "Sony", color: "info" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Lenovo", value: "Lenovo" },
          { label: "Dell", value: "Dell" },
          { label: "HP", value: "HP" },
          { label: "Samsung", value: "Samsung" },
          { label: "Microsoft", value: "Microsoft" },
          { label: "Apple", value: "Apple" },
          { label: "MI", value: "MI" },
          { label: "Acer", value: "Acer" },
          { label: "ASUS", value: "ASUS" },
          { label: "MSI", value: "MSI" },
          { label: "Sony", value: "Sony" },
        ],
        defaultValues: ['Lenovo', 'Dell', 'HP', 'Samsung', 'Microsoft', 'Apple', 'MI', 'Acer', 'ASUS', 'MSI', 'Sony'],
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "brand",
        showOrHideFields: [],
        fieldName: "brand",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "name",
        type: "uniqueField",
        placeholder: "SerialNumber",
        label: "Serial Number",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Serial Number",
        derivedValue: "sNo=SerialNumber=SerialNumber=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "sNo",
        fieldType: "Link",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "invoiceNumber",
        type: "number",
        placeholder: "InvoiceNumber",
        label: "InvoiceNumber",
        width: "120px",
        addFormOrder: 11,
        editFormOrder: 11,
        header: "Invoice Number",
        derivedValue: "invoiceNumber=InvoiceNumber=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "invoiceNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "invoiceNumber",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "purchaseDate",
        type: "date",
        placeholder: "PurchaseDate",
        label: "PurchaseDate",
        width: "130px",
        addFormOrder: 12,
        editFormOrder: 12,
        header: "PurchaseDate",
        derivedValue: "purchaseDate=PurchaseDate=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "purchaseDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "Center",
        show: true,
        field: "purchaseDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "status",
        type: "dropDown",
        placeholder: "Status",
        label: "Status",
        width: "130px",
        addFormOrder: 13,
        editFormOrder: 13,
        header: "Status",
        derivedValue: "status=Status=Status=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "status",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: true,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: "InStock", value: "InStock", color: "primary" },
          { label: "Assigned", value: "Assigned", color: "info" },
          { label: "Repair", value: "Repair", color: "success" },
          { label: "Sold", value: "Sold", color: "secondary" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "InStock", value: "InStock" },
          { label: "Assigned", value: "Assigned" },
          { label: "Repair", value: "Repair" },
          { label: "Sold", value: "Sold" },
        ],
        defaultValues: ['InStock', 'Assigned', 'Repair', 'Sold'],
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "status",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "assignedTo",
        type: "relateAutoComplete",
        placeholder: "AssignedTo",
        label: "Assigned To",
        width: "150px",
        addFormOrder: 14,
        editFormOrder: 14,
        header: "Assigned To",
        derivedValue: "assignedTo=AssignedTo=AssignedTo=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "assignedToName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: "employee",
        searchField: "displayName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["name"],
        controllerName: "employee",
        searchApi: "employees",
        isMultiple: false,
        textAlign: "Center",
        show: true,
        field: "assignedTo",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "false",
        displayineditForm: "false",
        mobile: true,
        tDisplay: true,
      },
      {
        name: "purchaseDocumentLink",
        type: "text",
        placeholder: "PurchaseDocumentLink",
        label: "PurchaseDocumentLink",
        width: "130px",
        addFormOrder: 15,
        editFormOrder: 15,
        header: "Purchased DocumentLink",
        derivedValue: "purchaseDocumentLink=PurchaseInventoryLink=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "purchaseDocumentLink",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "purchaseDocumentLink",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "driveLink",
        style: {
          color: "#f17038",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "assignedDocumentLink",
        type: "text",
        placeholder: "AssignedDocumentLink",
        label: "AssignedDocumentLink",
        width: "130px",
        addFormOrder: 16,
        editFormOrder: 16,
        header: "Assigned DocumentLink",
        derivedValue: "assignedDocumentLink=AssignedInventoryLink=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "assignedDocumentLink",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "assignedDocumentLink",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "driveLink",
        tDisplay: true,
      },
      {
        name: "description",
        type: "textarea",
        placeholder: "Description",
        label: "Description",
        width: "200px",
        addFormOrder: 17,
        editFormOrder: 17,
        header: "Description",
        derivedValue: "description=description=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "description",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "comments",
        type: "ckeditor",
        placeholder: "Comments",
        label: "Comments",
        width: "130px",
        addFormOrder: 24,
        editFormOrder: 24,
        header: "Comments",
        derivedValue: "comments=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "comments",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "comments",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
    ];
    return data;
  };

  const getFormFields = () => {

    return [
      {
        name: "type",
        type: "dropDown",
        placeholder: "Type",
        label: "Type",
        width: "130px",
        addFormOrder: 7,
        editFormOrder: 7,
        derivedValue: "type=Type=Type=Type=Type=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "type",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: true,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: "Laptop", value: "Laptop", color: "primary" },
          { label: "Ipad", value: "Ipad", color: "primary" },
          { label: "MacBook", value: "MacBook", color: "primary" },
          { label: "Furniture", value: "Furniture", color: "primary" },
          { label: "Desktop", value: "Desktop", color: "primary" },
          { label: "Projector", value: "Projector", color: "primary" },
          { label: "Others", value: "Others", color: "primary" },
        ],
        fieldType: "dropDown",
        dependent: [],
        show: true,
        showOrHideFields: [],
        fieldName: "type",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "operatingSystem",
        type: "dropDown",
        placeholder: "OperatingSystem",
        label: "OperatingSystem",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        derivedValue:
          "operatingSystem=OperatingSystem=OperatingSystem=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "operatingSystem",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: "Windows", value: "Windows", color: "success" },
          { label: "Ubuntu", value: "Ubuntu", color: "info" },
          { label: "MacOS", value: "MacOS", color: "danger" },
        ],
        fieldType: "dropDown",
        dependent: [],
        show: true,
        showOrHideFields: [],
        fieldName: "operatingSystem",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "brand",
        type: "dropDown",
        placeholder: "Brand",
        label: "Brand",
        width: "130px",
        addFormOrder: 9,
        editFormOrder: 9,
        derivedValue: "brand=Brand=Brand=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "brand",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: "Lenovo", value: "Lenovo", color: "secondary" },
          { label: "Dell", value: "Dell", color: "success" },
          { label: "HP", value: "HP", color: "danger" },
          { label: "Samsung", value: "Samsung", color: "warning" },
          { label: "Microsoft", value: "Microsoft", color: "primary" },
          { label: "Apple", value: "Apple", color: "primary" },
          { label: "MI", value: "MI", color: "primary" },
          { label: "Acer", value: "Acer", color: "primary" },
          { label: "ASUS", value: "ASUS", color: "primary" },
          { label: "MSI", value: "MSI", color: "primary" },
          { label: "Sony", value: "Sony", color: "primary" },
        ],
        fieldType: "dropDown",
        dependent: [],
        show: true,
        showOrHideFields: [],
        fieldName: "brand",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "sNo",
        type: "uniqueField",
        placeholder: "SerialNumber",
        label: "SerialNumber",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        derivedValue: "sNo=SerialNumber=SerialNumber=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "sNo",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: true,
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        fieldName: "sNo",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "invoiceNumber",
        type: "number",
        placeholder: "InvoiceNumber",
        label: "InvoiceNumber",
        width: "120px",
        addFormOrder: 11,
        editFormOrder: 11,
        derivedValue: "invoiceNumber=InvoiceNumber=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "invoiceNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "purchaseDate",
        type: "date",
        placeholder: "PurchaseDate",
        label: "PurchaseDate",
        width: "130px",
        addFormOrder: 12,
        editFormOrder: 12,
        derivedValue: "purchaseDate=PurchaseDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "purchaseDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "status",
        type: "dropDown",
        placeholder: "Status",
        label: "Status",
        width: "130px",
        addFormOrder: 13,
        editFormOrder: 13,
        derivedValue: "status=Status=Status=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "status",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: true,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: "InStock", value: "InStock", color: "primary" },
          { label: "Assigned", value: "Assigned", color: "primary" },
          { label: "Repair", value: "Repair", color: "primary" },
          { label: "Sold", value: "Sold", color: "primary" },
        ],
        fieldType: "dropDown",
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "assignedTo",
        type: "relateAutoComplete",
        placeholder: "AssignedTo",
        label: "AssignedTo",
        width: "150px",
        addFormOrder: 14,
        editFormOrder: 14,
        derivedValue: "assignedTo=AssignedTo=AssignedTo=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "assignedTo",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: "employee",
        searchField: "displayName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["name"],
        controllerName: "employee",
        searchApi: "employees",
        filterCriteria: { key: 'status', value: 'Active', type: 'eq' },
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "purchaseDocumentLink",
        type: "text",
        placeholder: "PurchaseDocumentLink",
        label: "PurchaseDocumentLink",
        width: "130px",
        addFormOrder: 15,
        editFormOrder: 15,
        derivedValue: "purchaseDocumentLink=PurchaseInventoryLink=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "purchaseDocumentLink",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "assignedDocumentLink",
        type: "text",
        placeholder: "AssignedDocumentLink",
        label: "AssignedDocumentLink",
        width: "130px",
        addFormOrder: 16,
        editFormOrder: 16,
        derivedValue: "assignedDocumentLink=AssignedInventoryLink=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "assignedDocumentLink",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "description",
        type: "textarea",
        placeholder: "Description",
        label: "Description",
        width: "200px",
        addFormOrder: 17,
        editFormOrder: 17,
        derivedValue: "description=description=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "comments",
        type: "ckeditor",
        placeholder: "Comments",
        label: "Comments",
        width: "130px",
        addFormOrder: 24,
        editFormOrder: 24,
        derivedValue: "comments=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "comments",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: false,
      },
    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
    // await this.formModalRef.getRowData(item, 'edit');
  };

  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
        fieldName: "createdByName",
        controllerName: "employee",
        searchApi: "employees",
        tDisplay: true,
      },
      {
        name: "updatedByName",
        type: "Text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        filter: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
        fieldName: "updatedByName",
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "created",
        fieldName: "created",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "updated",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "type",
        type: "dropDown",
        placeholder: "Type",
        label: "Type",
        width: "130px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "Type",
        derivedValue: "type=Type=Type=Type=Type=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "type",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: true,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: "All", value: "All", color: "primary" },
          { label: "Laptop", value: "Laptop", color: "primary" },
          { label: "Ipad", value: "Ipad", color: "primary" },
          { label: "MacBook", value: "MacBook", color: "primary" },
          { label: "Furniture", value: "Furniture", color: "primary" },
          { label: "Desktop", value: "Desktop", color: "primary" },
          { label: "Projector", value: "Projector", color: "primary" },
          { label: "Others", value: "Others", color: "primary" },
        ],
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "type",
        showOrHideFields: [],
        label0: "All",
        color0: "primary",
        value0: "All",
        label1: "Laptop",
        color1: "primary",
        value1: "Laptop",
        label2: "Ipad",
        color2: "primary",
        value2: "Ipad",
        label3: "MacBook",
        color3: "primary",
        value3: "MacBook",
        label4: "Furniture",
        color4: "primary",
        value4: "Furniture",
        label5: "Desktop",
        color5: "primary",
        value5: "Desktop",
        label6: "Projector",
        color6: "primary",
        value6: "Projector",
        label7: "Others",
        color7: "primary",
        value7: "Others",
        fieldName: "type",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "operatingSystem",
        type: "dropDown",
        placeholder: "OperatingSystem",
        label: "OperatingSystem",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "OperatingSystem",
        derivedValue:
          "operatingSystem=OperatingSystem=OperatingSystem=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "operatingSystem",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: "All", value: "All", color: "warning" },
          { label: "Windows", value: "Windows", color: "success" },
          { label: "Ubuntu", value: "Ubuntu", color: "info" },
          { label: "MacOS", value: "MacOS", color: "danger" },
        ],
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "operatingSystem",
        showOrHideFields: [],
        label0: "All",
        color0: "warning",
        value0: "All",
        label1: "Windows",
        color1: "success",
        value1: "Windows",
        label2: "Ubuntu",
        color2: "info",
        value2: "Ubuntu",
        label3: "MacOS",
        color3: "danger",
        value3: "MacOS",
        fieldName: "operatingSystem",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "brand",
        type: "dropDown",
        placeholder: "Brand",
        label: "Brand",
        width: "130px",
        addFormOrder: 9,
        editFormOrder: 9,
        header: "Brand",
        derivedValue: "brand=Brand=Brand=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "brand",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: "Lenovo", value: "Lenovo", color: "secondary" },
          { label: "Dell", value: "Dell", color: "success" },
          { label: "HP", value: "HP", color: "danger" },
          { label: "Samsung", value: "Samsung", color: "warning" },
          { label: "Microsoft", value: "Microsoft", color: "primary" },
          { label: "Apple", value: "Apple", color: "primary" },
          { label: "MI", value: "MI", color: "primary" },
          { label: "Acer", value: "Acer", color: "primary" },
          { label: "ASUS", value: "ASUS", color: "primary" },
          { label: "MSI", value: "MSI", color: "primary" },
          { label: "Sony", value: "Sony", color: "primary" },
        ],
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "brand",
        showOrHideFields: [],
        label0: "Lenovo",
        color0: "secondary",
        value0: "Lenovo",
        label1: "Dell",
        color1: "success",
        value1: "Dell",
        label2: "HP",
        color2: "danger",
        value2: "HP",
        label3: "Samsung",
        color3: "warning",
        value3: "Samsung",
        label4: "Microsoft",
        color4: "primary",
        value4: "Microsoft",
        label5: "Apple",
        color5: "primary",
        value5: "Apple",
        label6: "MI",
        color6: "primary",
        value6: "MI",
        label7: "Acer",
        color7: "primary",
        value7: "Acer",
        label8: "ASUS",
        color8: "primary",
        value8: "ASUS",
        label9: "MSI",
        color9: "primary",
        value9: "MSI",
        label10: "Sony",
        color10: "primary",
        value10: "Sony",
        fieldName: "brand",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "sNo",
        type: "uniqueField",
        placeholder: "SerialNumber",
        label: "SerialNumber",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "SerialNumber",
        derivedValue: "sNo=SerialNumber=SerialNumber=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "sNo",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "sNo",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "invoiceNumber",
        type: "number",
        placeholder: "InvoiceNumber",
        label: "InvoiceNumber",
        width: "120px",
        addFormOrder: 11,
        editFormOrder: 11,
        header: "InvoiceNumber",
        derivedValue: "invoiceNumber=InvoiceNumber=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "invoiceNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: false,
        field: "invoiceNumber",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "purchaseDate",
        type: "date",
        placeholder: "PurchaseDate",
        label: "PurchaseDate",
        width: "130px",
        addFormOrder: 12,
        editFormOrder: 12,
        header: "PurchaseDate",
        derivedValue: "purchaseDate=PurchaseDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "purchaseDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "Center",
        show: false,
        field: "purchaseDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "status",
        type: "dropDown",
        placeholder: "Status",
        label: "Status",
        width: "130px",
        addFormOrder: 13,
        editFormOrder: 13,
        header: "Status",
        derivedValue: "status=Status=Status=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "status",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: true,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: "InStock", value: "InStock", color: "primary" },
          { label: "Assigned", value: "Assigned", color: "primary" },
          { label: "Repair", value: "Repair", color: "primary" },
          { label: "Sold", value: "Sold", color: "primary" },
        ],
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "status",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "assignedTo",
        type: "relateAutoComplete",
        placeholder: "AssignedTo",
        label: "AssignedTo",
        width: "150px",
        addFormOrder: 14,
        editFormOrder: 14,
        header: "AssignedTo",
        derivedValue: "assignedTo=AssignedTo=AssignedTo=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "assignedTo",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: "employee",
        searchField: "name",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["name"],
        controllerName: "employee",
        searchApi: "employees",
        isMultiple: false,
        textAlign: "Center",
        show: true,
        field: "assignedTo",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "employee_name",
        type: "text",
        placeholder: "employee_name",
        label: "employee_name",
        header: "employee name",
        width: 110,
        parentField: "assignedTo",
        id: "employee_name",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "name",
        show: true,
        field: "employee_name",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "purchaseDocumentLink",
        type: "text",
        placeholder: "PurchaseDocumentLink",
        label: "PurchaseDocumentLink",
        width: "130px",
        addFormOrder: 15,
        editFormOrder: 15,
        header: "PurchaseDocumentLink",
        derivedValue: "purchaseDocumentLink=PurchaseInventoryLink=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "purchaseDocumentLink",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: false,
        field: "purchaseDocumentLink",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "assignedDocumentLink",
        type: "text",
        placeholder: "AssignedDocumentLink",
        label: "AssignedDocumentLink",
        width: "130px",
        addFormOrder: 16,
        editFormOrder: 16,
        header: "AssignedDocumentLink",
        derivedValue: "assignedDocumentLink=AssignedInventoryLink=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "assignedDocumentLink",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: false,
        field: "assignedDocumentLink",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Description",
        type: "textarea",
        placeholder: "Description",
        label: "Description",
        width: "200px",
        addFormOrder: 17,
        editFormOrder: 17,
        header: "Description",
        derivedValue: "Description=Description=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "Description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: false,
        field: "Description",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "companyEmpId",
        type: "text",
        placeholder: "CompanyEmpId",
        label: "CompanyEmpId",
        width: "130px",
        addFormOrder: 16,
        editFormOrder: 16,
        header: "CompanyEmpId",
        derivedValue: "companyEmpId=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "companyEmpId",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: false,
        field: "companyEmpId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "assignedDate",
        type: "date",
        placeholder: "AssignedDate",
        label: "AssignedDate",
        width: "130px",
        addFormOrder: 17,
        editFormOrder: 17,
        header: "AssignedDate",
        derivedValue: "assignedDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "assignedDate",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "Center",
        show: false,
        field: "assignedDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "repairDate",
        type: "date",
        placeholder: "RepairDate",
        label: "RepairDate",
        width: "130px",
        addFormOrder: 18,
        editFormOrder: 18,
        header: "RepairDate",
        derivedValue: "repairDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "repairDate",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "Center",
        show: false,
        field: "repairDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "count",
        type: "number",
        placeholder: "Count",
        label: "Count",
        width: "120px",
        addFormOrder: 19,
        editFormOrder: 19,
        header: "Count",
        derivedValue: "count=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "count",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: false,
        field: "count",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "name",
        type: "text",
        placeholder: "Name",
        label: "Name",
        width: "130px",
        addFormOrder: 20,
        editFormOrder: 20,
        header: "Name",
        derivedValue: "name=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "name",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: false,
        field: "name",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "soldDate",
        type: "date",
        placeholder: "SoldDate",
        label: "SoldDate",
        width: "130px",
        addFormOrder: 21,
        editFormOrder: 21,
        header: "SoldDate",
        derivedValue: "soldDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "soldDate",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "Center",
        show: false,
        field: "soldDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "fromDate",
        type: "date",
        placeholder: "FromDate",
        label: "FromDate",
        width: "130px",
        addFormOrder: 22,
        editFormOrder: 22,
        header: "FromDate",
        derivedValue: "fromDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "fromDate",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "Center",
        show: false,
        field: "fromDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "toDate",
        type: "date",
        placeholder: "ToDate",
        label: "ToDate",
        width: "130px",
        addFormOrder: 23,
        editFormOrder: 23,
        header: "ToDate",
        derivedValue: "toDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "toDate",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "Center",
        show: false,
        field: "toDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "comments",
        type: "text",
        placeholder: "Comments",
        label: "Comments",
        width: "130px",
        addFormOrder: 24,
        editFormOrder: 24,
        header: "Comments",
        derivedValue: "comments=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "comments",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: false,
        field: "comments",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "assignedToName",
        type: "text",
        placeholder: "AssignedToName",
        label: "AssignedToName",
        width: "130px",
        addFormOrder: 25,
        editFormOrder: 25,
        header: "AssignedToName",
        derivedValue: "assignedToName=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "assignedToName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: false,
        field: "assignedToName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
    ];
    return data;
  };

  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };

  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    window.location.reload();

  };

  const saveDataToServer = async (item, field, value, getDataFromServer) => {
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }
    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.inventories}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          if (response && response.respCode) {
            getDataFromServer();
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  const isEditPermission = rolePermission?.type == "Edit" ? true : false
  const actionTypes = [
    {
      name: "Delete",
      options: [
        {
          label: "Delete",
          value: "Delete",
          show: rolePermission?.actions?.includes("Delete") ? true : false,
          multiple: true,
        },
      ],
    },
    {
      name: "InStock",
      action: saveDataToServer,
      options: [
        {
          label: "InStock",
          field: "status",
          value: "InStock",
          show: rolePermission?.actions?.includes("Approve/Reject") ? true : false,
          multiple: false,
        },
      ],
    },
    {
      name: "Assigned",
      action: saveDataToServer,
      options: [
        {
          label: "Assigned",
          field: "status",
          value: "Assigned",
          show: rolePermission?.actions?.includes("Approve/Reject") ? true : false,
          multiple: false,
        },
      ],
    },
    {
      name: "Repair",
      action: saveDataToServer,
      options: [
        {
          label: "Repair",
          field: "status",
          value: "Repair",
          show: rolePermission?.actions?.includes("Approve/Reject") ? true : false,
          multiple: false,
        },
      ],
    },
    {
      name: "Sold",
      action: saveDataToServer,
      options: [
        {
          label: "Sold",
          field: "status",
          value: "Sold",
          show: rolePermission?.actions?.includes("Approve/Reject") ? true : false,
          multiple: false,
        },
      ],
    },
  ]

  return (
    <span>
      {
        <DataTables
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          addRequired={isEditPermission}
          editRequired={isEditPermission}
          deleteRequired={isEditPermission}
          viewRequired={isEditPermission}
          exportRequired={isEditPermission}
          sample={true}
          printRequried={true}
          actionsTypes={actionTypes}
          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath.inventories}
          globalSearch={
            "type/operatingSystem/brand/status/purchaseDocumentLink/assignedDocumentLink/Description/name/comments"
          }
          displayName="Inventories"
          type="Inventories"
          routeTo={apiCalls.inventories}
          displayViewOfForm="sideForm"
          sideFormLeftOrRight="right"
          apiResponseKey={apiCalls.inventories.toLowerCase()}
          apiUrl={apiCalls.inventories}
          selectedId={params.id}
        />
      }
      {isOpenFormModal ? (
        <FormModal
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="inventories"
          apiUrl={apiCalls.inventories}
        />
      ) : null}
    </span>
  );
};

export default Inventories;