import React, { useEffect, useState } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
// config file

const LeaveHistories = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);

  const params = useParams();
  useEffect(() => {
    let screenPermissions =
      RolePermissions.screenPermissions("Leave Histories");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
  }, []);

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };

  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };

  const getTableFields = () => {

    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "S.No",
        filter: false,
        sortable: false,
        placeholder: "Search",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        sortable: true,
        filter: true,
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
        tDisplay: true,
      },
      // {
      //   name: "updatedByName",
      //   type: "Text",
      //   placeholder: "Updated By",
      //   label: "Updated By",
      //   width: 120,
      //   header: "Updated By",
      //   id: "updatedByName",
      //   displayinlist: "true",
      //   fieldType: "Text",
      //   textAlign: "Center",
      //   show: true,
      //   filter: true,
      //   field: "updatedByName",
      //   mobile: true,
      //   displayInSettings: true,
      //   tDisplay: true,
      // },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Date",
        derivedValue: "created=undefined",
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "DD-MM-YYYY",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "created",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      // {
      //   name: "updated",
      //   type: "date",
      //   placeholder: "Updated",
      //   label: "Updated",
      //   width: 90,
      //   header: "Updated",
      //   derivedValue: "updated=undefined",
      //   sortable: true,
      //   filter: true,
      //   actions: [],
      //   actionsNumber: [],
      //   id: "updated",
      //   displayinaddForm: "false",
      //   displayineditForm: "false",
      //   displayinlist: "true",
      //   globalSearchField: "false",
      //   controllerId: null,
      //   fieldType: "Date",
      //   dateFormat: "DD-MM-YYYY",
      //   textAlign: "Center",
      //   disabled: true,
      //   show: true,
      //   field: "updated",
      //   mobile: true,
      //   displayInSettings: true,
      //   tDisplay: true,
      // },
      {
        name: "fromDate",
        type: "date",
        placeholder: "FromDate",
        label: "FromDate",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "From Date",
        derivedValue: "fromDate=FromDate=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "fromDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "Center",
        show: true,
        field: "fromDate",
        showOrHideFields: [],
        fieldName: "fromDate",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "toDate",
        type: "date",
        placeholder: "ToDate",
        label: "ToDate",
        width: "130px",
        addFormOrder: 9,
        editFormOrder: 9,
        header: "To Date",
        derivedValue: "toDate=ToDate=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "toDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "Center",
        show: true,
        field: "toDate",
        showOrHideFields: [],
        fieldName: "toDate",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "type",
        type: "dropDown",
        placeholder: "Type",
        label: "Type",
        width: "130px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Type",
        derivedValue: "type=Type=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "type",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: "LeaveCreate", value: "LeaveCreate", color: "primary" },
          { label: "LeaveUpdated", value: "LeaveUpdated", color: "primary" },
        ],
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "type",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "numberOfDays",
        type: "number",
        placeholder: "NumberOfDays",
        label: "NumberOfDays",
        width: "120px",
        addFormOrder: 11,
        editFormOrder: 11,
        header: "No Of Days",
        derivedValue: "numberOfDays=NumberOfDays=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "numberOfDays",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "numberOfDays",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "afterLeaves",
        type: "number",
        placeholder: "AfterLeaves",
        label: "AfterLeaves",
        width: "120px",
        addFormOrder: 12,
        editFormOrder: 12,
        header: "After Leaves",
        derivedValue: "afterLeaves=AfterLeaves=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "afterLeaves",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "afterLeaves",
        showOrHideFields: [],
        fieldName: "afterLeaves",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "displayName",
        type: "text",
        placeholder: "DisplayName",
        label: "DisplayName",
        width: "130px",
        addFormOrder: 14,
        editFormOrder: 14,
        header: "Name",
        derivedValue: "displayName=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "displayName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "displayName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "companyEmpId",
        type: "text",
        placeholder: "CompanyEmpId",
        label: "CompanyEmpId",
        width: "130px",
        addFormOrder: 15,
        editFormOrder: 15,
        header: "CompanyEmpId",
        derivedValue: "companyEmpId=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "companyEmpId",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: false,
        field: "companyEmpId",
        showOrHideFields: [],
        fieldName: "companyEmpId",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
    ];
    return data;
  };

  const getFormFields = () => {

    return [
      {
        name: "name",
        type: "text",
        placeholder: "Name",
        label: "Name",
        width: 110,
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        fieldType: "Link",
        globalSearchField: "true",
        show: true,
        fieldName: "name",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        disabled: true,
        show: true,
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        disabled: true,
        show: true,
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "date",
        type: "date",
        placeholder: "Date",
        label: "Date",
        width: "130px",
        addFormOrder: 7,
        editFormOrder: 7,
        derivedValue: "date=Date=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "date",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        fieldName: "date",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "fromDate",
        type: "date",
        placeholder: "FromDate",
        label: "FromDate",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        derivedValue: "fromDate=FromDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "fromDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        fieldName: "fromDate",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "toDate",
        type: "date",
        placeholder: "ToDate",
        label: "ToDate",
        width: "130px",
        addFormOrder: 9,
        editFormOrder: 9,
        derivedValue: "toDate=ToDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "toDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        fieldName: "toDate",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "type",
        type: "dropDown",
        placeholder: "Type",
        label: "Type",
        width: "130px",
        addFormOrder: 10,
        editFormOrder: 10,
        derivedValue: "type=Type=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "type",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: "LeaveCreate", value: "LeaveCreate", color: "primary" },
          { label: "LeaveUpdated", value: "LeaveUpdated", color: "primary" },
        ],
        fieldType: "dropDown",
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "numberOfDays",
        type: "number",
        placeholder: "NumberOfDays",
        label: "NumberOfDays",
        width: "120px",
        addFormOrder: 11,
        editFormOrder: 11,
        derivedValue: "numberOfDays=NumberOfDays=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "numberOfDays",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "afterLeaves",
        type: "number",
        placeholder: "AfterLeaves",
        label: "AfterLeaves",
        width: "120px",
        addFormOrder: 12,
        editFormOrder: 12,
        derivedValue: "afterLeaves=AfterLeaves=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "afterLeaves",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        fieldName: "afterLeaves",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "operation",
        type: "text",
        placeholder: "operation",
        label: "operation",
        width: "130px",
        addFormOrder: 12,
        editFormOrder: 12,
        derivedValue: "operation=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "operation",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "beforeLeaves",
        type: "text",
        placeholder: "BeforeLeaves",
        label: "BeforeLeaves",
        width: "130px",
        addFormOrder: 13,
        editFormOrder: 13,
        derivedValue: "beforeLeaves=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "beforeLeaves",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "displayName",
        type: "text",
        placeholder: "DisplayName",
        label: "DisplayName",
        width: "130px",
        addFormOrder: 14,
        editFormOrder: 14,
        derivedValue: "displayName=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "displayName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "companyEmpId",
        type: "text",
        placeholder: "CompanyEmpId",
        label: "CompanyEmpId",
        width: "130px",
        addFormOrder: 15,
        editFormOrder: 15,
        derivedValue: "companyEmpId=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "companyEmpId",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        fieldName: "companyEmpId",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "reason",
        type: "text",
        placeholder: "Reason",
        label: "Reason",
        width: "130px",
        addFormOrder: 16,
        editFormOrder: 16,
        derivedValue: "reason=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "reason",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "email",
        type: "email",
        placeholder: "Email",
        label: "Email",
        width: "150px",
        addFormOrder: 17,
        editFormOrder: 17,
        derivedValue: "email=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "email",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
    // await this.formModalRef.getRowData(item, 'edit');
  };

  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "displayName",
        type: "text",
        placeholder: "Name",
        label: "Name",
        header: "Name",
        width: 110,
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        fieldType: "Link",
        textAlign: "Center",
        globalSearchField: "true",
        show: true,
        field: "dsiplayName",
        fieldName: "dsiplayName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updatedByName",
        type: "Text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        filter: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Date",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "created",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "updated",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "fromDate",
        type: "date",
        placeholder: "FromDate",
        label: "FromDate",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "From Date",
        derivedValue: "fromDate=FromDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "fromDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "Center",
        show: true,
        field: "fromDate",
        showOrHideFields: [],
        fieldName: "fromDate",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "toDate",
        type: "date",
        placeholder: "ToDate",
        label: "ToDate",
        width: "130px",
        addFormOrder: 9,
        editFormOrder: 9,
        header: "To Date",
        derivedValue: "toDate=ToDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "toDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "Center",
        show: true,
        field: "toDate",
        showOrHideFields: [],
        fieldName: "toDate",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "type",
        type: "dropDown",
        placeholder: "Type",
        label: "Type",
        width: "130px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Type",
        derivedValue: "type=Type=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "type",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: "LeaveCreate", value: "LeaveCreate", color: "primary" },
          { label: "LeaveUpdated", value: "LeaveUpdated", color: "primary" },
        ],
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "type",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "numberOfDays",
        type: "number",
        placeholder: "NumberOfDays",
        label: "NumberOfDays",
        width: "120px",
        addFormOrder: 11,
        editFormOrder: 11,
        header: "No Of Days",
        derivedValue: "numberOfDays=NumberOfDays=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "numberOfDays",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "numberOfDays",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "afterLeaves",
        type: "number",
        placeholder: "AfterLeaves",
        label: "AfterLeaves",
        width: "120px",
        addFormOrder: 12,
        editFormOrder: 12,
        header: "After Leaves",
        derivedValue: "afterLeaves=AfterLeaves=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "afterLeaves",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "afterLeaves",
        showOrHideFields: [],
        fieldName: "afterLeaves",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "operation",
        type: "text",
        placeholder: "operation",
        label: "operation",
        width: "130px",
        addFormOrder: 12,
        editFormOrder: 12,
        header: "operation",
        derivedValue: "operation=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "operation",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: false,
        field: "operation",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "beforeLeaves",
        type: "text",
        placeholder: "BeforeLeaves",
        label: "BeforeLeaves",
        width: "130px",
        addFormOrder: 13,
        editFormOrder: 13,
        header: "BeforeLeaves",
        derivedValue: "beforeLeaves=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "beforeLeaves",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: false,
        field: "beforeLeaves",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "displayName",
        type: "text",
        placeholder: "DisplayName",
        label: "DisplayName",
        width: "130px",
        addFormOrder: 14,
        editFormOrder: 14,
        header: "DisplayName",
        derivedValue: "displayName=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "displayName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: false,
        field: "displayName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "companyEmpId",
        type: "text",
        placeholder: "CompanyEmpId",
        label: "CompanyEmpId",
        width: "130px",
        addFormOrder: 15,
        editFormOrder: 15,
        header: "CompanyEmpId",
        derivedValue: "companyEmpId=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "companyEmpId",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: false,
        field: "companyEmpId",
        showOrHideFields: [],
        fieldName: "companyEmpId",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "reason",
        type: "text",
        placeholder: "Reason",
        label: "Reason",
        width: "130px",
        addFormOrder: 16,
        editFormOrder: 16,
        header: "Reason",
        derivedValue: "reason=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "reason",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: false,
        field: "reason",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "email",
        type: "email",
        placeholder: "Email",
        label: "Email",
        width: "150px",
        addFormOrder: 17,
        editFormOrder: 17,
        header: "Email",
        derivedValue: "email=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "email",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: false,
        field: "email",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
    ];
    return data;
  };

  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };

  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    window.location.reload();

  };

  const saveDataToServer = async (item, field, value) => {
    let body = item[0];
    body[field] = value;
    let userBody = Object.assign({}, body);
    if (body) {
      let method, apiUrl;
      method = "PUT";
      apiUrl = `${apiCalls.leaveHistories}/${body._id}`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          if (response && response.respCode) {
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  const isEditPermission = rolePermission && rolePermission.type == "Edit" ? true : false

  return (
    <span>
      {
        <DataTables
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          addRequired={isEditPermission}
          editRequired={isEditPermission}
          deleteRequired={isEditPermission}
          viewRequired={isEditPermission}
          exportRequired={isEditPermission}
          sample={false}
          printRequried={false}
          settingsRequired={true}
          filterRequired={false}
          gridRequried={false}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath.leaveHistories}
          globalSearch={"name/type"}
          displayName="Leave Histories"
          type="LeaveHistories"
          routeTo={apiCalls.leaveHistories}
          displayViewOfForm="screen"
          sideFormLeftOrRight=""
          apiResponseKey={apiCalls.leaveHistories.toLowerCase()}
          apiUrl={apiCalls.leaveHistories}
          selectedId={params.id}
        />
      }
      {isOpenFormModal ? (
        <FormModal
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="leaveHistories"
          apiUrl={apiCalls.leaveHistories}
        />
      ) : null}
    </span>
  );
};

export default LeaveHistories;
