import React, { useRef } from "react";
import { FileUpload } from 'primereact/fileupload';
import { useState, useEffect } from 'react';
import fetchMethodRequest from '../../../config/service';
import showToasterMessage from '../../../containers/UI/ToasterMessage/toasterMessage';
import config from '../../../config/config';
import { Link } from "react-router-dom";

const DoFileUpload = (props) => {
    const [imageUrl, setImageUrl] = useState([]);
    const fileInputRef = useRef(null);

    let newFiles = props.field.value;

    const onUpload = async (event) => {

        fetchMethodRequest('POST', props.url, event.files, 'upload', 'mul')
            .then(async (response) => {
                if (response && response.respCode) {
                    let fileName = response?.fileName;
                    if (fileName && fileName.length > 0) {
                        let newFiles = fileName.map(file => file.name);
                        let existingImages = props.field.value || [];
                        let images = [...existingImages, ...newFiles];
                        // setImageUrl(images);
                        if (props.field) {
                            props.field.onChange(images)
                        }
                        clearFileUpload();
                        showToasterMessage('Uploaded successfully', 'success');
                    }
                } else if (response && response.errorMessage) {
                    showToasterMessage('upload failed')
                }

            }).catch((err) => {
                return err;
            });
    };

    const checkImg = (img) => {
        // console.log('img', img)
        if (img) {
            return config.imageFormats.some(x => img.toLowerCase().includes(x));
        }
        return false;
    };

    const deleteFile = (img) => {
        if (img) {
            let allFiles = newFiles.filter(file => file !== img)
            props.field.onChange(allFiles)
        }
    };

    const clearFileUpload = () => {
        if (fileInputRef.current) {
            fileInputRef.current.clear()
        }
    };
    return (
        <div className='d-flex flex-column'>
            <FileUpload
                ref={fileInputRef}
                name={props.name ? props.name : 'file'}
                url={props.url}
                accept={props.acceptType ? props.acceptType : '*'}
                maxFileSize={props.maxFileSize ? props.maxFileSize : 2000000}
                customUpload
                uploadHandler={onUpload}
                multiple={props.multiple ? props.multiple : true}
                chooseLabel={props.label ? props.label : 'Choose File'}
            />
            <div>
                {newFiles && newFiles.length > 0 && (
                    <div className="d-flex flex-wrap">
                        {newFiles.map(img => (
                            <div className="row w-50" >
                                <div className="col-7 my-2">
              
                                     {props.type && props.type === 'profile' ?
                                        (<Link to={`${config.profileUrl}${img}`} target="_blank" style={{backgroundColor: 'red'}}>
                                            <img src={config.profileUrl + img} style={{ width: '100%', height: '100%' }} alt="" />
                                        </Link>) :
                                        checkImg(img) ? (
                                            <Link to={`${config.imgUrl}${props.type.toLowerCase()}/${img}`} target="_blank">
                                                <img src={config.imgUrl + `${props.type.toLowerCase()}/` + img} style={{ width: '80%' }} alt="" />
                                             </Link>
                                         ) : (
                                             <a href={`${config.imgUrl}${props.type.toLowerCase()}/${img}`} target='_blank' >
                                                 <img title={img} src={configImages?.[img?.split('.')?.[img?.split('.')?.length - 1]]} alt="" />
                                             </a>
                                         )}

                                    
                                </div>
                                <div className="col-3 my-3">
                                    <button className="btn text-danger" type='button' onClick={() => deleteFile(img)}>
                                        <i className="pi pi-trash" />
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>

    );
};
export default DoFileUpload;