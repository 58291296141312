import React, { useEffect, useState, useRef } from "react";
import { AutoComplete } from 'primereact/autocomplete';
import fetch from '../../../config/service';
import config from "../../../config/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";

const DoAutoCompleteField = (props) => {
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [isValidate, setIsValidate] = useState(false);
    const [noData, setNoData] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [dependencyfieldError, setDependencyFieldError] = useState(false)

    const id = props.id ? props.id : props.name;
    const label = props.label ? props.label : props.name;
    const name = props.name;
    const type = props.type ? props.type : 'text';
    let field = props.field;
    const placeholder = props.placeholder;
    const screen = props.screen;
    const login = JSON.parse(localStorage.getItem('loginCredentials'))
    const isDisable = props.isDisable ? true : false

    const isDependencyRef = useRef(true);

    const onSelectRecord = (e) => {
        field.onChange(e);
        // setTimeout(() => {
        //     setNoData(false);
        // }, 1000)
        props.onChange(e.value);
    }

    // to remove duplicates
    const removeDuplicates = () => {
        if (this.state.filteredSuggestions) {
            setSuggestions(this.state.filteredSuggestions);
        }
    }

    const getAllSuggestions = (event, type) => {
        if (!props.item.hasDependency || (props.item.hasDependency && props.item.hasDependencyField && props.getValues(props.item.hasDependencyField) && props.getValues(props.item.hasDependencyField)["_id"])) {
            setDependencyFieldError(false)
            let url;
            setIsValidate(false);
            setNoData(false);
            setErrorMessage('');
            let filterCriteria = {
                limit: 30,
                page: 1,
                sortfield: "created",
                direction: "desc",
                criteria: []
            };

            if (props.item.hasDependencyField && props.getValues(props.item.hasDependencyField) && props.getValues(props.item.hasDependencyField)["_id"]) {
                filterCriteria['criteria'].push({
                    "key": props.item.fieldForKey,
                    "value": props.getValues(props.item.hasDependencyField)["_id"],
                    "type": "eq"
                })
            }

            if (props.criteria) {
                filterCriteria['criteria'].push(props.criteria)
            }
            if (screen == 'Projects' && name === 'teamLead') {
                filterCriteria['criteria'].push({ key: 'roleType', value: ['Manager', 'Admin'], type: 'in' })
            }
            if (screen === 'Employees' && props.searchApi === 'employees') {
                if (props.item.name == 'hrReportingTo') {
                    filterCriteria['criteria'].push({ key: 'roleType', value: 'HR', type: 'eq' })
                } else {
                    filterCriteria['criteria'].push({ key: 'roleType', value: ['Manager'], type: 'in' })
                }
            }
            if (event && event.query) {
                filterCriteria['criteria'].push({
                    "key": props.item.searchField,
                    "value": event.query,
                    "type": "regexOr"
                });
            };

            let apiUrl = props.searchApi;
            url = `${apiUrl}?searchFrom=autoComplete&filter=${JSON.stringify(filterCriteria)}`;
            if ((screen == 'Tasks' || screen == 'TimeSheets') && props.searchApi == 'projects' && props.name == "projectId") {
                url = `${apiUrl}?filter=${JSON.stringify(filterCriteria)}`;
            }
            return fetch('GET', url)
                .then((response) => {
                    if (response) {
                        let dropdownData = [];
                        if (response[apiUrl] && response[apiUrl].length && response[apiUrl].length > 0) {
                            dropdownData = response[apiUrl];
                        }
                        if (dropdownData && dropdownData.length == 0) {
                            setFilteredSuggestions([]);
                            setNoData(true);
                        }
                        if (props.searchApi === 'employees' && (screen === 'TimeSheets' || screen === 'Leaves') && login.roleType !== 'Admin') {
                            let employeeReporties = setEmployeeDropdown(response.employees, login.displayName)
                            //including login employee in the dropdown list
                            const loginEmployee = response.employees.find(emp => emp._id === login._id);
                            if (loginEmployee) {
                                employeeReporties.push(loginEmployee);
                            }
                            setFilteredSuggestions(employeeReporties)
                        }
                        else {
                            setSuggestions(dropdownData);
                        }
                    }
                }).catch((err) => {
                    return err;
                });
        }
        else {
            setDependencyFieldError(true)
            return;
        }
    }

    //Displaying employees based on reporting person
    let setEmployeeDropdown = (employees, reportee, selectedEmployees = []) => {
        let reportingEmployees = employees.filter(employee => employee.reportingToName === reportee);

        selectedEmployees.push(...reportingEmployees);

        // Recursively find reporting employees for each reporting employee
        for (let employee of reportingEmployees) {
            setEmployeeDropdown(employees, employee.displayName, selectedEmployees);
        }
        return selectedEmployees;
    };

    const setSuggestions = async (dropdownData) => {
        if (props.input && props.input.value) {
            if (props.input.value.length > 0) {
                let values = props.input.value;
                if (Array.isArray(values)) {
                    values.forEach(element => {
                        let field = '_id';
                        dropdownData = dropdownData.filter((item) => item[field] !== element[field]);
                    });
                }
            }
        }
        setFilteredSuggestions(dropdownData);
    }

    let markRequired = () => {
        return (
            <FontAwesomeIcon
                color='red'
                icon={faAsterisk}
                style={{ width: '7px', marginBottom: "5px", marginLeft: "2px" }}
            />
        );
    };

    const onSelect = (e) => {
        if (name == 'projectId') {
            props.setValue('taskId', '')
            props.setValue('sprint', '')
        }
        if (!props.getValues(props.item.name)) {
            props.setValue(props.item.name, e.value)
        }
        if (screen === "Tasks" && props.formType === 'add' && props.item && props.item.dependent) {
            if (e.value[props.item.dependent.key] == props.item.dependent.value && isDependencyRef.current) {
                props.handleFnEnableControlsBasedOnValue1(props.item.dependent.field, true)
            } else {
                props.handleFnEnableControlsBasedOnValue1(props.item.dependent.field, false)
            }
        }
    }

    const itemTemplate = (item) => {
        let name = item[props.item['searchField']];
        return (
            <div className="country-item">
                <div><span style={{ color: 'seagreen' }}>{item[props.templateField]}</span> - {name}</div>
            </div >
        );
    };

    return (
        <div className="flex flex-column">

            <div className="flex align-center"> {/* Added a wrapper div with flex class */}
                <label htmlFor={id} className="text-capitalize">{label}</label>
                {props.markReq && props.markReq === true && markRequired()}
            </div>
            <AutoComplete
                disabled={isDisable}
                inputId={field.name}
                value={field.value}
                onChange={onSelectRecord}
                inputRef={field.ref}
                suggestions={filteredSuggestions}
                completeMethod={getAllSuggestions}
                placeholder={placeholder}
                field={props.searchField}
                dropdown={true}
                multiple={props.multiple}
                onSelect={onSelect}
                selectionLimit={props.selectionLimit ? props.selectionLimit : config.selectionLimit}
                itemTemplate={props.showItemTemplate ? itemTemplate : false}
            />

            <small className="text-danger ">{props.fieldState.invalid ? props.errors[props.name]?.message : ''}</small>
            {noData && <span className="form__form-group-error" style={{ marginTop: '5px' }}>
                {props.searchApi == 'tasks' ? 'No Tasks Found' : props.searchApi == 'projects' ? 'No Projects Assigned' : 'No Data Found'}
            </span>}
            {props.item.hasDependency && dependencyfieldError ?
                <span className="form__form-group-error" style={{ marginTop: '5px' }}>{`Please Select project First`}</span>
                : null
            }
        </div>
    )

}

export default DoAutoCompleteField;