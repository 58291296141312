import { Badge } from 'primereact/badge';

const CustomBadge = (props) => {

    const showColor = (color) => {
        switch (color) {
            case "primary":
                color = "#326fd1";
                break;
            case "danger":
                color = "red";
                break;
            case "success":
                color = "#198754";
                break;
            case "warning":
                color = "#eec137";
                break;
            case "info":
                color = "#0dcaf0";
                break;
            case "light":
                color = "#808080";
                break;
            case "dark":
                color = "dark";
                break;
            case "secondary":
                color = "#9fa9b7";
                break;
            default:
                // color remains unchanged
                break;
        }

        return color;
    };

    return (
        <>
            <Badge
                value={props.val}
                style={props.val && props.val !== "" ? { backgroundColor: showColor(props.color) } : { display: 'none' }}
            >
            </Badge>
        </>
    )
}

export default CustomBadge;