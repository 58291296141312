import React, { useState, useEffect, useRef } from 'react';
import DoCKEditorField from './Fields/DoEditor';
import { Link } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const ReplyMail = (props) => {

    let mailContent = props.rowDataForReplyMail;

    let [ckEditorData, setCkEditorData] = useState('');

    let replyMailFields = [
        { label: "Total Received", name: "totalReceived", placeholder: "Total Received" },
        { label: "Closed", name: "closed", placeholder: "Closed" },
        { label: "Reopened", name: "reopened", placeholder: "Reopened" },
        { label: "New Issues", name: "newIssues", placeholder: "New Issues" },
        { label: "Not Verified", name: "notVerified", placeholder: "Not Verified" },
    ]

    const [inputValues, setInputValues] = React.useState(
        replyMailFields.reduce((acc, item) => ({ ...acc, [item.name]: '' }), {})
    );

    const handleInputChange = (name, value) => {
        setInputValues({ ...inputValues, [name]: value });
    };

    let formReplyMail = () => {

        mailContent["issuesCount"] = inputValues;
        mailContent["qaComment"] = props.editorData;
        props.saveDataToServer(mailContent)
    }

    let getTableForReleaseScreenEdit = () => {
        return (
            <div className='myTable'>
                <DataTable value={props.selectedReleaseItems} >
                    <Column field="name" header="Task"></Column>
                    <Column field="taskID" header="Task ID"></Column>
                </DataTable>
            </div>
        )
    }

    return (
        <>
            <div className="row" style={{ marginBottom: "15px" }}>
                {replyMailFields.map((item, index) => (
                    <div className='col-sm-6' key={index}>
                        <div className="form__form-group mb-3 ml-1">
                            <div>
                                <div className="flex flex-column">
                                    <div className="flex align-center">
                                        <label htmlFor={item.name} className="text-capitalize" style={{ fontSize: '13px' }}>
                                            {item.label}
                                        </label>
                                    </div>
                                    <InputText
                                        id={item.name}
                                        name={item.name}
                                        placeholder={item.placeholder}
                                        value={inputValues[item.name]}
                                        onChange={(e) => handleInputChange(item.name, e.target.value)}
                                    />
                                    <small className="text-danger"></small>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div>{getTableForReleaseScreenEdit()}</div>
            <div style={{ marginTop: "15px" }}>

                <DoCKEditorField
                    getEditorText={props.getEditorText}
                    ckEditorData={ckEditorData}
                    setCkEditorData={setCkEditorData}
                />

            </div>

            <div className='float-end' style={{ paddingTop: '1rem' }}>
                <Link to={props.routeTo.startsWith("/") ? props.routeTo : `/${props.routeTo}`} onClick={props.closeFormModal}>
                    <Button color='primary' outline type="submit" style={{ marginRight: '10px' }}
                    >Discard
                    </Button>
                </Link>
                <Button color='primary' outline type="submit" onClick={formReplyMail}
                >Send Mail
                </Button>
            </div>
        </>
    );
}
export default ReplyMail;