import React, { useEffect, useState } from "react";
import { Dialog } from 'primereact/dialog';

const LinkedTaskDialog = (props) => {

    return(<>
    <Dialog header={<span style={{ color: 'coral' }}>Note</span>}  visible={props.linkTaskDialog} style={{ width: '50vw' }} onHide={() => props.setLinkTaskDialog(false)}>
    <p className="m-0">
        Linked Task status is not "Ready For Testing".
    </p>
    </Dialog>
    </>)
}

export default LinkedTaskDialog;