import React, { useEffect, useState } from 'react';
import DataTables from '../../CommonDataTable/DataTable';
import config from '../../../../config/config';
import apiCalls from '../../../../config/apiCalls'
import store from '../../../App/store';
import RolePermissions from '../../CommonModals/Permissions';
import ViewModal from '../../CommonModals/viewModal';
import { useParams } from 'react-router';
// config file
const Activities = (props) => {

	const [type, setType] = useState('Activities');
	const [rolePermission, setRolePermission] = useState('Activities');

	const params = useParams();
	const getDerivedStateFromProps = (props, state) => {
		let storeData = store.getState()
		let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
		return { languageData: languageData };
	}



	useEffect(() => {
		let screenPermissions = RolePermissions.screenPermissions('Activities');
		if (screenPermissions) {
			setRolePermission(screenPermissions);
		}

	}, [])

	const getTableFields = () => {
		let data = [
			{
				textAlign: "center",
				width: 47,
				field: "Sno",
				header: "S.No",
				label: "Sno",
				filter: false,
				sortable: false,
				mobile: true,
				placeholder: "Search",
				show: true,
				displayInSettings: true,
			},
			{
				textAlign: "center",
				show: true,
				mobile: true,
				width: 80,
				field: "created",
				label: "Created",
				fieldType: "Date",
				type: 'date',
				dateFormat: config.fullDateFormat,
				header: "Created Date",
				filter: true,
				sortable: true,
				displayInSettings: true,
			},
			{
				textAlign: "left",
				width: 80,
				field: "context",
				mobile: true,
				header: "Context",
				label: "Context",
				filter: true,
				sortable: true,
				show: true,
				textCapitalize: true,
				displayInSettings: true,
			},
			{
				textAlign: "left",
				width: 80,
				field: "contextType",
				mobile: true,
				label: "Context Type",
				header: "Context Type",
				filter: true,
				sortable: true,
				show: true,
				displayInSettings: true,
			},
			{
				textAlign: "left",
				width: 80,
				field: "email",
				mobile: true,
				label: "Email",
				header: "Email",
				filter: true,
				sortable: true,
				show: true,
				displayInSettings: true,
			},
			{
				textAlign: "left",
				width: 80,
				field: "name",
				mobile: true,
				label: "Employee",
				header: "Employee",
				filter: true,
				sortable: true,
				show: true,
				displayInSettings: true,
			},
			{
				show: true,
				textAlign: "left",
				width: 80,
				mobile: true,
				field: "desc",
				header: "Description",
				filter: true,
				label: "Description",
				sortable: true,
				textCapitalize: true,
				displayInSettings: true,
			},
			{
				textAlign: "left",
				width: 80,
				field: "ipAddress",
				label: "Ip Address",
				mobile: true,
				header: 'Ip Address',
				filter: true,
				sortable: true,
				show: true,
				displayInSettings: true,
			},
			{
				textAlign: "left",
				width: 80,
				field: "deviceType",
				label: "Device Type",
				mobile: true,
				header: 'Device Type',
				filter: true,
				sortable: true,
				show: true,
				displayInSettings: true,
			},
			{
				textAlign: "left",
				width: 80,
				field: "browserName",
				label: "Browser Name",
				mobile: true,
				header: 'Browser Name',
				filter: true,
				sortable: true,
				show: true,
				displayInSettings: true,
			},
			{
				textAlign: "left",
				width: 80,
				field: 'osName',
				label: "Os Name",
				mobile: true,
				header: 'Os Name',
				filter: true,
				sortable: true,
				show: true,
				displayInSettings: true,
				"type": "dropDown",
				"fieldType": "dropDown",
				"filterElement": [
					{
						"label": "Windows",
						"value": "Windows",
						"color": "primary"
					},
					{
						"label": "Linux",
						"value": "Linux",
						"color": "primary"
					}
				],
				"options": [
					{
						"label": "Windows",
						"value": "Windows",
						"color": "primary"
					},
					{
						"label": "Linux",
						"value": "Linux",
						"color": "primary"
					}
				]
			},
			{
				textAlign: "left",
				width: 80,
				field: 'osVersion',
				label: "Os Version",
				mobile: true,
				header: 'Os Version',
				filter: false,
				sortable: true,
				show: true,
				displayInSettings: true,
			},]

		return data;
	};


	const getFormFields = () => {
		return ([
			{
				'show': false,
				"value": "",
				"type": "text",
				"name": "firstName",
				"label": "First Name",
				"id": "firstName",
				"placeholder": "First Name",
				"required": true
			},
			{ "name": "CreatedBy", "type": "relateAutoComplete", "placeholder": "Created By", "label": "Created By", "width": "150px", "addFormOrder": 5, "editFormOrder": 5, "derivedValue": "CreatedBy=undefined", "capitalizeTableText": false, "sortable": false, "actions": [], "actionsNumber": [], "id": "CreatedBy", "displayinaddForm": "true", "displayineditForm": "true", "displayinlist": "true", "isFieldRequired": "false", "required": false, "displayOptionsInActions": false, "globalSearchField": "false", "controllerId": 1001, "searchField": "name", "fieldType": "relateAutoComplete", "populteFields": [], "displayFields": ["name"], "controllerName": "employee", "searchApi": "employees", "isMultiple": false, "show": true, "showOrHideFields": [], "fieldName": "CreatedBy", "mobile": true, "displayInSettings": true, "isAddFormHidden": false, "isEditFormHidden": false },

		]);
	}

	return (
		<span>
			<DataTables
				getTableFields={getTableFields}
				formFields={getFormFields}
				actionsTypes={[{
					'name': 'Delete',
					"options": [
						{ 'label': 'Delete', 'value': 'Delete', 'show': rolePermission?.type == "Edit" ? true : false, "multiple": true, },
					]
				},
				]}
				exportRequried={false}
				printRequried={false}
				addRequried={false}
				editRequired={false}
				deleteRequired={false}
				viewRequired={false}
				settingsRequired={true}
				filterRequired={false}
				gridRequried={false}
				sample={false}
				globalSearchFieldName='activity'
				globalSearch={"Context/Email/Description"}
				type="Activities"
				displayName='Activities'
				apiResponseKey={"activities"}
				apiUrl={"activities"}
				routeTo='activities'
				displayViewOfForm='screen'
				selectedId={params.id}
			/>
		</span>
	);

}

export default Activities;