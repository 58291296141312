import React, { useEffect, useState } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
import ApproveOrReject from "../../CommonModals/ApproveOrReject";
import moment from 'moment'
// config file

const TimeSheets = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);
  const [minDate, setMinDate] = useState();
  const [isHolidays, setIsHolidays] = useState(false);

  let loginRole = JSON.parse(localStorage.getItem('loginCredentials'))

  const params = useParams();

  useEffect(() => {
    let screenPermissions = RolePermissions.screenPermissions("TimeSheets");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
  }, []);

  useEffect(() => {
    if (loginRole && loginRole.roleType && loginRole.roleType == 'Employee') {
      getLeaveHolidays();
      if (isHolidays) {
        setIsHolidays(true)
        setMinDate(getMonday())
      } else {
        setMinDate(getMonday())
      }
    }
  }, [isHolidays])

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };

  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };

  const customFun = (rowData) => {
    let arr = [];

    if (rowData && rowData.status) {
      if (rowData.status === "Pending" || rowData.status === "Rejected") {
        arr.push('edit', 'delete');
      } else if (rowData.status === 'Approved') {
        arr.push('delete');
      }
    }
    return arr
  }

  const getTableFields = () => {

    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "S.No",
        filter: false,
        sortable: false,
        placeholder: "Search",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        sortable: true,
        filter: true,
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        field: "createdByName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updatedByName",
        type: "Text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        sortable: true,
        filter: true,
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "created",
        isClickable: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "DD-MM-YYYY",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "created",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "DD-MM-YYYY",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "updated",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "false",
        displayineditForm: "false",
        mobile: true,
        tDisplay: true,
        customFun: customFun,
      },
      {
        name: "employeeName",
        type: "textarea",
        placeholder: "employeeName",
        label: "employeeName",
        width: "100px",
        addFormOrder: 14,
        editFormOrder: 14,
        header: "employeeName",
        derivedValue:
          "employeeName=employeeName=employeeName=employeeName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "employeeName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        style: { width: '250px', textOverflow: "ellipsis" },
        show: false,
        field: "employeeName",
        showOrHideFields: [],
        fieldName: "employeeName",
        mobile: true,
        displayInSettings: false,
        tDisplay: true,
      },
      {
        name: "date",
        type: "date",
        placeholder: "Date",
        label: "Date",
        width: "130px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "Date",
        derivedValue: "date=date=date=Date=Date=undefined",
        capitalizeTableText: false,
        isClickable: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "date",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "Center",
        show: true,
        field: "date",
        fieldName: "date",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "employee",
        type: "relateAutoComplete",
        placeholder: "Employee",
        label: "Employee",
        width: "150px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Employee",
        derivedValue: "Employee=SelectEmployee=SelectEmployee=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "employee",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: "employee",
        searchField: "displayName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["email"],
        controllerName: "employee",
        searchApi: "employees",
        isMultiple: false,
        textAlign: "Center",
        show: true,
        field: "employee",
        showOrHideFields: [],
        fieldName: "employee",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "projectName",
        type: "relateAutoComplete",
        placeholder: "Project",
        label: "Project",
        width: "150px",
        addFormOrder: 22,
        editFormOrder: 22,
        header: "Project",
        derivedValue:
          "projectName=projectName=projectName=projectName=projectName=projectName=projectName=projectName=projectName=projectName=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "projectName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1007,
        searchField: "name",
        fieldType: "relateAutoComplete",
        displayFields: ["name"],
        controllerName: "Project",
        searchApi: "projects",
        textAlign: "Center",
        show: true,
        field: "projectId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "taskName",
        type: "relateAutoComplete",
        placeholder: "Task",
        label: "Task",
        width: "150px",
        addFormOrder: 24,
        editFormOrder: 24,
        header: "Task",
        derivedValue:
          "taskName=taskName=taskName=taskName=taskName=taskName=taskName=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "taskName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1008,
        searchField: "name",
        fieldType: "relateAutoComplete",
        displayFields: ["taskSummary"],
        controllerName: "Task",
        searchApi: "tasks",
        textAlign: "Center",
        style: { width: '350px', textOverflow: "ellipsis" },
        show: true,
        field: "taskId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "type",
        type: "radio",
        placeholder: "Work Type",
        label: "Work Type",
        width: "120px",
        addFormOrder: 12,
        editFormOrder: 12,
        header: "Work Type",
        derivedValue: "type=type=type=type=type=WorkType=WorkType=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "type",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        options: [
          { label: "Regular", value: "Regular", color: "primary" },
          { label: "Client Location", value: "Client Location", color: "success" },
          { label: "Work From Home", value: "Work From Home", color: "secondary" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Regular", value: "Regular" },
          { label: "Client Location", value: "Client Location" },
          { label: "Work From Home", value: "Work From Home" },
        ],
        defaultValues: ['Regular', 'Client Location', 'Work From Home'],
        textAlign: "Center",
        show: true,
        field: "type",
        showOrHideFields: [],
        fieldName: "type",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "hours",
        type: "number",
        placeholder: "Hours",
        label: "Hours",
        width: "120px",
        addFormOrder: 21,
        editFormOrder: 21,
        header: "Hours",
        derivedValue: "hours=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "hours",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "hours",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "hours",
        type: "number",
        placeholder: "Hours",
        label: "Hours",
        width: "120px",
        addFormOrder: 21,
        editFormOrder: 21,
        header: "Hours",
        derivedValue: "hours=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "hours",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: false,
        field: "timesheetHours",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: false,
        tDisplay: true,
      },
      {
        name: "description",
        type: "textarea",
        placeholder: "Description",
        label: "Description",
        width: "200px",
        addFormOrder: 14,
        editFormOrder: 14,
        header: "Description",
        derivedValue:
          "description=description=description=Description=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        style: { width: '250px', textOverflow: "ellipsis" },
        show: true,
        field: "description",
        showOrHideFields: [],
        fieldName: "description",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "companyEmpId",
        type: "uniqueID",
        placeholder: "Employee Id",
        label: "Employee Id",
        width: "120px",
        addFormOrder: 14,
        editFormOrder: 14,
        header: "Employee Id",
        derivedValue:
          "companyEmpId=companyEmpId=companyEmpId=companyEmpId=companyEmpId=EmployeeID=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "companyEmpId",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        uniqueIdLength: "12",
        textAlign: "Center",
        show: true,
        field: "companyEmpId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "taskCode",
        type: "text",
        placeholder: "Task ID",
        label: "Task ID",
        width: "130px",
        addFormOrder: 15,
        editFormOrder: 15,
        header: "Task ID",
        derivedValue: "taskCode=taskCode=TaskCode=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "taskCode",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "taskCode",
        showOrHideFields: [],
        fieldName: "taskCode",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "sprintName",
        type: "text",
        placeholder: "Sprint Name",
        label: "Sprint Name",
        width: "150px",
        addFormOrder: 23,
        editFormOrder: 23,
        header: "Sprint Name",
        derivedValue: "sprint=sprint=sprint=sprintName=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "sprint",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        isMultiple: true,
        textAlign: "Center",
        show: true,
        field: "sprintName",
        fieldType: "Array",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "status",
        type: "dropDown",
        placeholder: "Status",
        label: "Status",
        width: "130px",
        addFormOrder: 11,
        editFormOrder: 11,
        header: "Status",
        derivedValue:
          "status=Status=Status=Status=Status=Status=Status=Status=Status=Status=Status=Status=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "status",
        displayinaddForm: "false",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: true,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: "Pending", value: "Pending", color: "danger" },
          { label: "Approved", value: "Approved", color: "success" },
          { label: "Rejected", value: "Rejected", color: "secondary" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Pending", value: "Pending", color: "warning" },
          { label: "Approved", value: "Approved", color: "success" },
          { label: "Rejected", value: "Rejected", color: "info" },
        ],
        // defaultValues: ['Pending', 'Approved', 'Rejected'],
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "status",
        showOrHideFields: [],
        fieldName: "status",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
    ];
    return data;
  };

  const getFormFields = () => {

    return [
      {
        name: "date",
        type: "date",
        placeholder: "Date",
        value: new Date(),
        label: "Date",
        width: "130px",
        addFormOrder: 1,
        editFormOrder: 1,
        derivedValue: "date=date=date=Date=Date=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "date",
        minDate: minDate,
        maxDate: new Date(),
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        fieldName: "date",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "employee",
        type: "relateAutoComplete",
        value: loginRole && loginRole.companyEmail ? loginRole : '',
        placeholder: "Employee",
        label: "Employee Name",
        width: "150px",
        addFormOrder: 2,
        editFormOrder: 2,
        header: "Employee",
        derivedValue: "Employee=SelectEmployee=SelectEmployee=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "employee",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: "employee",
        searchField: "displayName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["email"],
        controllerName: "employee",
        searchApi: "employees",
        filterCriteria: { key: 'status', value: 'Active', type: 'eq' },
        isMultiple: false,
        textAlign: "Center",
        show: true,
        field: "employee",
        showOrHideFields: [],
        fieldName: "employee",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        isShowItemTemplate: true,
        templateField: 'employeeId'
      },
      {
        name: "projectId",
        type: "relateAutoComplete",
        placeholder: "Project Name",
        label: "Project",
        width: "150px",
        addFormOrder: 3,
        editFormOrder: 3,
        derivedValue:
          "projectId=projectId=projectId=Project=Project=Project=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "projectId",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: true,
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: "Project",
        searchField: "name",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["ProjectName"],
        controllerName: "Project",
        searchApi: "projects",
        filterCriteria: { key: 'status', value: 'Active', type: 'eq' },
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        fieldName: "projectId",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "taskId",
        type: "relateAutoComplete",
        placeholder: "Task Name",
        label: "Task",
        width: "150px",
        addFormOrder: 4,
        editFormOrder: 4,
        derivedValue:
          "taskId=taskId=taskId=Task=Task=Task=Task=Task=Task=Task=Task=Task=Task=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "taskId",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: true,
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: "Task",
        hasDependency: true,
        fieldForKey: "projectId",
        hasDependencyField: "projectId",
        searchField: "name",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["TaskSummary"],
        controllerName: "Task",
        searchApi: "tasks",
        filterCriteria: { key: 'taskStatus', value: ['new', 'in development', 'dev completed', 'analysis/design', 'review completed', 'ready for testing', 'need clarification', 'reopen', 'resolved', 'not an issue'], type: 'in' },
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        fieldName: "taskId",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        isShowItemTemplate: true,
        templateField: 'taskID'
      },
      {
        name: "type",
        type: "radio",
        placeholder: "Work Type",
        value: "Regular",
        label: "Work Type",
        width: "120px",
        addFormOrder: 5,
        editFormOrder: 5,
        derivedValue: "type=type=type=type=type=WorkType=WorkType=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "type",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        options: [
          { label: "Regular", value: "Regular" },
          { label: "Client Location", value: "Client Location" },
          { label: "Work From Home", value: "Work From Home" },
        ],
        fieldType: "radio",
        show: true,
        showOrHideFields: [],
        label0: "Regular",
        value0: "Regular",
        label1: "Client Location",
        value1: "Client Location",
        label2: "Work From Home",
        value2: "Work From Home",
        fieldName: "type",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "hours",
        type: "float",
        placeholder: "Hours",
        label: "Hours",
        width: "120px",
        addFormOrder: 6,
        editFormOrder: 6,
        derivedValue: "hours=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "hours",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "description",
        type: "textarea",
        placeholder: "Description",
        label: "Description",
        width: "200px",
        addFormOrder: 7,
        editFormOrder: 7,
        derivedValue:
          "description=description=description=Description=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        fieldName: "description",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
    ];
  };

  const getMonday = () => {
    var d = new Date();
    var day = d.getDay(),
      diff = d.getDate() - day + ((day == 1 || day == 0) ? -6 : isHolidays ? -6 : 1);     // Adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  /**
  * List call for Employees leaves and public holidays
  * @returns current week holidays
  */
  const getHolidays = async () => {
    try {
      const response = await fetchMethodRequest('GET', `leaves/weeklyDates`);
      if (response && response.length > 0) {

        const employeeLeaves = await response.flatMap((item) => {
          if (item.fromDate !== item.toDate) {
            const startDate = new Date(item.fromDate);
            const endDate = new Date(item.toDate);

            // Calculate the number of days between startDate and endDate
            const dateDifference = (endDate - startDate) / (24 * 60 * 60 * 1000);

            // Generate an array of dates in the range
            const dateArray = Array.from({ length: dateDifference + 1 }, (_, index) => {
              const currentDate = new Date(startDate);
              currentDate.setDate(startDate.getDate() + index);
              return currentDate.toISOString();
            });
            return dateArray;

          } else if (item.fromDate) {
            return [new Date(item.fromDate).toISOString()];
          } else if (item.date) {
            return [new Date(item.date).toISOString()];
          } else {
            return [];
          }
        });
        return employeeLeaves;
      }
    } catch (error) {
      throw error;
    }
  }

  //Function for extending dates in the timesheet
  const getLeaveHolidays = async () => {
    const monday = new Date();
    const currentDay = monday.getDay();
    const daysUntilMonday = 1 - currentDay;                // Calculate days until Monday
    monday.setDate(monday.getDate() + daysUntilMonday);    // Set the date to this week's Monday

    //call back function for list call
    const publicHolidays = await getHolidays();

    if (publicHolidays) {
      const firstDate = new Date(publicHolidays[0]);     //Taking first date from the array after sorting

      //This week's monday holiday or not
      const isMondayHoliday =
        firstDate.getDate() === monday.getDate() &&
        firstDate.getMonth() === monday.getMonth() &&
        firstDate.getFullYear() === monday.getFullYear();

      //If monday any holiday is there then only will extend dates for next
      if (isMondayHoliday) {
        sendHolidaysData(publicHolidays)
      }
    }
  }

  /**
   * calculating the leaves days and sending confirmation when after leaves finished
   * @param {Array} publicHolidays 
   */
  const sendHolidaysData = (publicHolidays) => {
    const currentDate = new Date();
    const currentDay = currentDate.getDay();
    let holidaysCount = 1;

    for (let i = 0; i < publicHolidays.length; i++) {
      currentDate.setDate(currentDate.getDate() - 1);  //previous day of current day

      //last day is included or not in the holidays list
      const isHolidayFinished = publicHolidays.includes(moment(currentDate).format('YYYY-MM-DD[T]00:00:00.000[Z]'))
      //including from monday holidays any of last day getting false breaking the loop
      if (isHolidayFinished) {
        holidaysCount++;

        if (holidaysCount === currentDay) {
          setIsHolidays(true);
          break;
        }
      } else {
        // Break the loop if it's not a holiday
        break;
      }
    }
  }

  const submit = async (item) => {
    setIsOpenFormModal(true);
    // await this.formModalRef.getRowData(item, 'edit');
  };

  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updatedByName",
        type: "Text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        filter: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "created",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "updated",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "false",
        displayineditForm: "false",
        mobile: true,
        tDisplay: true,
      },
      {
        name: "date",
        type: "date",
        placeholder: "Date",
        label: "Date",
        width: "130px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "Date",
        derivedValue: "date=date=date=Date=Date=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "date",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "Center",
        show: true,
        field: "date",
        fieldName: "date",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "employee",
        type: "relateAutoComplete",
        placeholder: "Employee",
        label: "Employee",
        width: "150px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Employee",
        derivedValue: "Employee=SelectEmployee=SelectEmployee=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "employee",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: "employee",
        searchField: "displayName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["email"],
        controllerName: "employee",
        searchApi: "employees",
        isMultiple: false,
        textAlign: "Center",
        show: true,
        field: "employee",
        showOrHideFields: [],
        fieldName: "employee",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "projectName",
        type: "relateAutoComplete",
        placeholder: "Project",
        label: "Project",
        width: "150px",
        addFormOrder: 22,
        editFormOrder: 22,
        header: "Project",
        derivedValue:
          "projectName=projectName=projectName=projectName=projectName=projectName=projectName=projectName=projectName=projectName=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "projectName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1007,
        searchField: "name",
        fieldType: "relateAutoComplete",
        displayFields: ["name"],
        controllerName: "Project",
        searchApi: "projects",
        textAlign: "Center",
        show: true,
        field: "projectId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "taskName",
        type: "relateAutoComplete",
        placeholder: "Task",
        label: "Task",
        width: "150px",
        addFormOrder: 24,
        editFormOrder: 24,
        header: "Task",
        derivedValue:
          "taskName=taskName=taskName=taskName=taskName=taskName=taskName=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "taskName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1008,
        searchField: "name",
        fieldType: "relateAutoComplete",
        displayFields: ["taskSummary"],
        controllerName: "Task",
        searchApi: "tasks",
        textAlign: "Center",
        show: true,
        field: "taskId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "type",
        type: "radio",
        placeholder: "Work Type",
        label: "Work Type",
        width: "120px",
        addFormOrder: 12,
        editFormOrder: 12,
        header: "Work Type",
        derivedValue: "type=type=type=type=type=WorkType=WorkType=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "type",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        options: [
          { label: "Regular", value: "Regular", color: "primary" },
          { label: "Client Location", value: "Client Location", color: "secondary" },
          { label: "Work From Home", value: "Work From Home", color: "primary" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Regular", value: "Regular" },
          { label: "Client Location", value: "Client Location" },
          { label: "Work From Home", value: "Work From Home" },
        ],
        defaultValues: ['Regular', 'Client Location', 'Work From Home'],
        textAlign: "Center",
        show: true,
        field: "type",
        showOrHideFields: [],
        fieldName: "type",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "hours",
        type: "number",
        placeholder: "Hours",
        label: "Hours",
        width: "120px",
        addFormOrder: 21,
        editFormOrder: 21,
        header: "Hours",
        derivedValue: "hours=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "hours",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "hours",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "description",
        type: "textarea",
        placeholder: "Description",
        label: "Description",
        width: "200px",
        addFormOrder: 14,
        editFormOrder: 14,
        header: "Description",
        derivedValue:
          "description=description=description=Description=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "description",
        showOrHideFields: [],
        fieldName: "description",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "companyEmpId",
        type: "uniqueID",
        placeholder: "Employee Id",
        label: "Employee Id",
        width: "120px",
        addFormOrder: 14,
        editFormOrder: 14,
        header: "Employee Id",
        derivedValue:
          "companyEmpId=companyEmpId=companyEmpId=companyEmpId=companyEmpId=EmployeeID=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "companyEmpId",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        uniqueIdLength: "12",
        textAlign: "Center",
        show: true,
        field: "companyEmpId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "taskCode",
        type: "text",
        placeholder: "Task ID",
        label: "Task ID",
        width: "130px",
        addFormOrder: 15,
        editFormOrder: 15,
        header: "Task ID",
        derivedValue: "taskCode=taskCode=TaskCode=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "taskCode",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "taskCode",
        showOrHideFields: [],
        fieldName: "taskCode",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "status",
        type: "text",
        placeholder: "Status",
        label: "Status",
        width: "130px",
        addFormOrder: 21,
        editFormOrder: 21,
        header: "Status",
        derivedValue: "status=status=status=status=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "status",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: false,
        field: "status",
        showOrHideFields: [],
        fieldName: "status",
        mobile: true,
        displayInSettings: false,
        tDisplay: true,
      },
    ];
    return data;
  };

  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };

  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    window.location.reload();

  };

  const saveDataToServer = async (item, field, value, getDataFromServer) => {
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }
    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.timeSheets}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          if (response && response.response.respCode) {
            getDataFromServer();
            showToasterMessage(response.response.respMessage, "success");
          } else if (response && response.response.errorMessage) {
            showToasterMessage(response.response.errorMessage, "error");
          }
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  //Displaying custom action icons approve and reject based on conditions
  const customActionButton = (item, getData, filters) => {
    return (
      <>
        {item.status == 'Pending' && (item.approved === true || loginRole.roleType === "Admin") ?
          <ApproveOrReject
            item={item}
            getDataFromServer={getData}
            filterCriteria={filters}
            type={'timeSheets'}
          />
          : null}
      </>
    )
  }

  const isEditPermission = rolePermission?.type == "Edit" ? true : false
  const actionTypes = [
    {
      name: "Delete",
      options: [
        {
          label: "Delete",
          value: "Delete",
          show: rolePermission?.actions?.includes("Delete") ? true : false,
          multiple: true,
        },
      ],
    },
    {
      name: "Approve",
      action: saveDataToServer,
      options: [
        {
          label: "Approve",
          field: "status",
          value: "Approved",
          show: (loginRole.roleType.toLowerCase() === "hr" || loginRole.roleType.toLowerCase() === "employee") ? false : true,
          multiple: true,
        },
      ],
    },
    {
      name: "Reject",
      action: saveDataToServer,
      options: [
        {
          label: "Reject",
          field: "status",
          value: "Rejected",
          show: (loginRole.roleType.toLowerCase() === "hr" || loginRole.roleType.toLowerCase() === "employee") ? false : true,
          multiple: true,
        },
      ],
    },
  ]

  return (
    <span>
      {
        <DataTables
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          addRequired={isEditPermission}
          editRequired={isEditPermission}
          deleteRequired={isEditPermission}
          viewRequired={isEditPermission}
          exportRequired={isEditPermission}
          sample={true}
          printRequried={true}
          actionsTypes={actionTypes}
          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath.timeSheets}
          globalSearch={"ProjectType/Hours/Description/TaskCode/OtherTask"}
          displayName="TimeSheets"
          type="TimeSheets"
          routeTo={apiCalls.timeSheets}
          displayViewOfForm="sideForm"
          sideFormLeftOrRight="right"
          colWidth="wide"
          apiResponseKey={apiCalls.timeSheets.toLowerCase()}
          apiUrl={apiCalls.timeSheets}
          selectedId={params.id}
          customActionButton={customActionButton}
        // defaultCriteria={[]}
        />
      }
      {isOpenFormModal ? (
        <FormModal
          // onRef={(ref) => (this.formModalRef = ref)}
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="timeSheets"
          apiUrl={apiCalls.timeSheets}
        />
      ) : null}
    </span>
  );
};

export default TimeSheets;
